import React, { Fragment } from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Footer from '../components/Footer'
import HeroSection from '../components/contentfulPageComponents/HeroSection'
import OneColumnSection from '../components/contentfulPageComponents/OneColumnSection'
import FormSection from '../components/contentfulPageComponents/FormSection'
import TwoColumnsSection from '../components/contentfulPageComponents/TwoColumnsSection'
import ThreeColumnsSection from '../components/contentfulPageComponents/ThreeColumnsSection'
import FourColumnsSection from '../components/contentfulPageComponents/FourColumnsSection'
import ThreeBoxSection from '../components/contentfulPageComponents/ThreeBoxSection'

interface FooterInfo {
    CTA?: string
    CTALink?: string
    Text?: string
}

class page extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const currentPageData: any = get(this, 'props.data.allContentfulPages.edges[0].node')
        // Variabales per sections
        // console.log(currentPageData)

        const allData = []
        let footerInfo: FooterInfo = {}

        // MAP COMPONENTS
        currentPageData.pageComponents.map((section) => {
            allData.push(section)
            if (section.internal.type == 'ContentfulHeroSection') {
                footerInfo = {
                    CTA: section.aboveFooterCta,
                    CTALink: section.aboveFooterCtaLink,
                    Text: section.aboveFooterText,
                }
            }
        })
        return (
            <Fragment>
                <Helmet>
                    <title>{currentPageData.title.title} | Lenses.io</title>
                    <meta name="twitter:text:title" content={currentPageData.title.title} />
                    <meta name="description" content={currentPageData.description.description} />
                    <meta
                        property="og:description"
                        content={currentPageData.description.description}
                    />
                    <meta
                        property="twitter:description"
                        content={currentPageData.description.description}
                    />

                    <meta name="keywords" content={currentPageData.keywords.keywords} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@lensesio" />
                    <meta property="og:title" content={currentPageData.title.title} />
                    <meta property="twitter:title" content={currentPageData.title.title} />
                    <meta property="og:image" content={currentPageData.oGimage.fluid.src} />
                    <meta
                        property="twitter:image"
                        content={'https:' + currentPageData.oGimage.fluid.src}
                    />
                </Helmet>

                {allData.map((section) => {
                    // console.log(section)
                    if (section.internal.type == 'ContentfulHeroSection') {
                        return <HeroSection key={section.id} hero={section} />
                    } else if (section.internal.type == 'ContentfulFormSection') {
                        return <FormSection key={section.id} formData={section} />
                    } else if (section.internal.type == 'ContentfulOneColumn') {
                        return <OneColumnSection key={section.id} oneColumnData={section} />
                    } else if (section.internal.type == 'ContentfulTwoColumns') {
                        return <TwoColumnsSection key={section.id} twoColumsData={section} />
                    } else if (section.internal.type == 'ContentfulThreeColumns') {
                        return <ThreeColumnsSection key={section.id} threeColumsData={section} />
                    } else if (section.internal.type == 'ContentfulFourColumns') {
                        return <FourColumnsSection key={section.id} fourColumsData={section} />
                    } else if (section.internal.type == 'ContentfulThreeBoxSection') {
                        return <ThreeBoxSection key={section.id} threeBoxData={section} />
                    }
                })}
                <Footer footerInfo={footerInfo} />
            </Fragment>
        )
    }
}

export default page

export const pageQueryG = graphql`
    query PageQuery($pageIdentifier: String!) {
        allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: $pageIdentifier } } }) {
            edges {
                node {
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        fluid(quality: 100) {
                            src
                        }
                    }
                    pageComponents {
                        ... on ContentfulThreeBoxSection {
                            firstBoxTitle
                            firstBoxCtaLink
                            firstBoxCtaText
                            internal {
                                type
                            }
                            firstBoxDescription {
                                raw
                            }
                            firstBoxImage {
                                title
                                fluid {
                                    src
                                }
                            }
                            secondBoxTitle
                            secondBoxCtaLink
                            secondBoxCtaText
                            secondBoxDescription {
                                raw
                            }
                            secondBoxImage {
                                title
                                fluid {
                                    src
                                }
                            }
                            thirdBoxCtaLink
                            thirdBoxCtaText
                            thirdBoxTitle
                            thirdBoxDescription {
                                raw
                            }
                            thirdBoxImage {
                                title
                                fluid {
                                    src
                                }
                            }
                        }
                        ... on ContentfulFormSection {
                            id
                            formName
                            formFields
                            buttonId
                            leftColumnImage {
                                title
                                fluid(quality: 100) {
                                    src
                                }
                            }
                            internal {
                                type
                            }
                            ctaLink
                            ctaText
                            formSourceForCrm
                            requiredFormFields
                            formLeftField {
                                raw
                            }
                        }
                        ... on ContentfulHeroSection {
                            id
                            heroLayout
                            logo {
                                title
                                fluid(quality: 100) {
                                    src
                                }
                            }
                            internal {
                                type
                            }
                            aboveFooterCta
                            aboveFooterCtaLink
                            aboveFooterText
                            navbarLayout
                            navbarLinkColor
                            subtitle
                            subtitleStyle
                            title
                            titleStyle
                            ctaLink
                            ctaText
                            image {
                                fluid(quality: 100) {
                                    src
                                }
                                title
                            }
                        }
                        ... on ContentfulTwoColumns {
                            id
                            backgroundColourWholeRow
                            layoutType
                            wholeRowMargin
                            columnsWidthRatio
                            internal {
                                type
                            }
                            columnContent {
                                backgroundColour
                                linksInNewTab
                                imagesWidth
                                backgroundImage {
                                    title
                                    fluid(quality: 100) {
                                        src
                                    }
                                }
                                shadow
                                isSticky
                                textColour
                                headingsColour
                                contentCentered
                                roundedEdges
                                fontSize
                                paddingY
                                paddingX
                                marginY
                                marginX
                                listStyle
                                content {
                                    raw
                                }
                            }
                        }
                        ... on ContentfulFourColumns {
                            id
                            backgroundColourWholeRow
                            layoutType
                            wholeRowMargin
                            internal {
                                type
                            }
                            columnContent {
                                backgroundColour
                                imagesWidth
                                linksInNewTab
                                backgroundImage {
                                    title
                                    fluid(quality: 100) {
                                        src
                                    }
                                }
                                shadow
                                isSticky
                                textColour
                                fontSize
                                headingsColour
                                contentCentered
                                roundedEdges
                                paddingY
                                paddingX
                                marginY
                                marginX
                                listStyle
                                content {
                                    raw
                                }
                            }
                        }
                        ... on ContentfulThreeColumns {
                            id
                            backgroundColourWholeRow
                            layoutType
                            wholeRowMargin
                            columnsWidthRatio
                            internal {
                                type
                            }
                            columnContent {
                                backgroundColour
                                imagesWidth
                                linksInNewTab
                                backgroundImage {
                                    title
                                    fluid(quality: 100) {
                                        src
                                    }
                                }
                                shadow
                                isSticky
                                fontSize
                                textColour
                                headingsColour
                                contentCentered
                                roundedEdges
                                paddingY
                                paddingX
                                marginY
                                marginX
                                listStyle
                                content {
                                    raw
                                }
                            }
                        }
                        ... on ContentfulOneColumn {
                            id
                            backgroundColourWholeRow
                            layoutType
                            columnAlignment
                            wholeRowMargin
                            internal {
                                type
                            }
                            columnContent {
                                backgroundColour
                                imagesWidth
                                linksInNewTab
                                backgroundImage {
                                    title
                                    fluid(quality: 100) {
                                        src
                                    }
                                }
                                shadow
                                isSticky
                                textColour
                                fontSize
                                headingsColour
                                contentCentered
                                roundedEdges
                                paddingY
                                paddingX
                                marginY
                                marginX
                                listStyle
                                content {
                                    raw
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
