import React, { Fragment } from 'react'
import ColumnContent from './ColumnContent'

const threeColumnsSection = ({ threeColumsData }) => {
    // console.log(threeColumsData)

    let threeColumnsContent
    let container
    let bgColor
    let RowMargin = 0
    let col1
    let col2
    let col3
    if (threeColumsData.layoutType) {
        if (threeColumsData.layoutType == 'Max') {
            container = 'container-1'
        } else if (threeColumsData.layoutType == 'Container') {
            container = 'container'
        } else if (threeColumsData.layoutType == 'Squashed') {
            container = 'container-squashed'
        }
    }

    if (threeColumsData.backgroundColourWholeRow) {
        if (threeColumsData.backgroundColourWholeRow == 'Gray') {
            bgColor = 'footer-color'
        } else if (threeColumsData.backgroundColourWholeRow == 'Red') {
            bgColor = 'bg-red'
        } else if (threeColumsData.backgroundColourWholeRow == 'Blue') {
            bgColor = 'bg-blue'
        }
    }

    if (threeColumsData.wholeRowMargin) {
        RowMargin = threeColumsData.wholeRowMargin / 2
        RowMargin = RowMargin + 'px'
        // console.log(RowMargin)
    }

    if (threeColumsData.columnsWidthRatio) {
        if (threeColumsData.columnsWidthRatio == '1:1:1') {
            col1 = 'col-lg-4 col-md-4 col-sm-12 col-12'
            col2 = 'col-lg-4 col-md-4 col-sm-12 col-12'
            col3 = 'col-lg-4 col-md-4 col-sm-12 col-12'
        } else if (threeColumsData.columnsWidthRatio == '2:1:1') {
            col1 = 'col-lg-6 col-md-6 col-sm-12 col-12'
            col2 = 'col-lg-3 col-md-3 col-sm-12 col-12'
            col3 = 'col-lg-3 col-md-3 col-sm-12 col-12'
        } else if (threeColumsData.columnsWidthRatio == '1:2:1') {
            col1 = 'col-lg-3 col-md-3 col-sm-12 col-12'
            col2 = 'col-lg-6 col-md-6 col-sm-12 col-12'
            col3 = 'col-lg-3 col-md-3 col-sm-12 col-12'
        } else if (threeColumsData.columnsWidthRatio == '1:1:2') {
            col1 = 'col-lg-3 col-md-3 col-sm-12 col-12'
            col2 = 'col-lg-3 col-md-3 col-sm-12 col-12'
            col3 = 'col-lg-6 col-md-6 col-sm-12 col-12'
        }
    }

    if (threeColumsData) {
        threeColumnsContent = (
            <Fragment>
                <div
                    className={bgColor && bgColor}
                    style={{
                        marginTop: `${RowMargin ? RowMargin : ''}`,
                        marginBottom: `${RowMargin ? RowMargin : ''}`,
                    }}>
                    <div className={container && container}>
                        <div className='d-flex flex-wrap'>
                            <div className={`${col1 && col1} mobile-margin-bottom`}>
                                <ColumnContent
                                    columnContent={
                                        threeColumsData.columnContent[0] &&
                                        threeColumsData.columnContent[0]
                                    }
                                />
                            </div>
                            <div className={`${col2 && col2} mobile-margin-bottom`}>
                                <ColumnContent
                                    columnContent={
                                        threeColumsData.columnContent[1] &&
                                        threeColumsData.columnContent[1]
                                    }
                                />
                            </div>
                            <div className={col3 && col3}>
                                <ColumnContent
                                    columnContent={
                                        threeColumsData.columnContent[2] &&
                                        threeColumsData.columnContent[2]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return threeColumnsContent
}

export default threeColumnsSection
