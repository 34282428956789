import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'

const footer = ({ footerInfo, newsletter, noFooter }) => {
    useEffect(() => {
        try {
            const interval = setInterval(function () {
                if (document.querySelector('#pi_tracking_opt_in_div')) {
                    document.querySelector('#pi_tracking_opt_in_div').style.cssText =
                        `bottom:20px;position: fixed;max-width:100%;width:995px;text-align: center;left: 50%;transform: translateX(-50%);background-color: #363943;color: #fff;font-size: 14px;padding: 10px 40px;z-index: 1000;border-radius: 25px;`
                    clearInterval(interval)
                }
            }, 100)
        } catch (err) {
            console.log(err)
        }

        // es-lint-disable-next-line
    }, [])
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    if (noFooter) {
        return <></>
    }

    return (
        <Fragment>
            {footerInfo && footerInfo.Text && (
                <section className='bg-dark p-5'>
                    <div className='container '>
                        <div className='d-flex flex-wrap'>
                            <div className='col-md-12 col-12 text-center roll-button'>
                                <p className='text-white paragraph-title pb-3'>{footerInfo.Text}</p>
                                <a className='bg-white primary-text' href={footerInfo.CTALink}>
                                    <span data-hover={footerInfo.CTA}>{footerInfo.CTA}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {/* Global Lenses.io footer */}

            <footer id='header-wrap '>
                {/* Newsletter */}
                <span className='footer d-none d-md-block'>
                    {!footerInfo && newsletter !== false && (
                        <Fragment>
                            <section className='p-4 footer-color'>
                                <div className='container'>
                                    <div className='d-flex flex-wrap'>
                                        <div className='col-12 text-center'>
                                            <a className='f-18 ml-4' href='/company/newsletter/'>
                                                JOIN OUR NEWSLETTER →{' '}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <hr />
                        </Fragment>
                    )}
                </span>

                <section className='footer-color pt-3 mobile-padding-reset'>
                    <div className='container pt-4'>
                        <div className='d-flex flex-wrap justify-content-center mb-0 mb-lg-3'>
                            {/*<div className='col-12 col-lg'>*/}
                            {/*<div className='roll-button'>*/}
                            {/*    {typeof location !== 'undefined' && (*/}
                            {/*        <a*/}
                            {/*            className='px-5 bg-light-blue text-white f-13 footer-cta'*/}
                            {/*            href={`${*/}
                            {/*                location.pathname === '/start/' ||*/}
                            {/*                location.pathname === '/start'*/}
                            {/*                    ? '/downloads/lenses-enterprise/?s=other'*/}
                            {/*                    : '/start/'*/}
                            {/*            }`}>*/}
                            {/*            <span data-hover='Free trial'>Free trial</span>*/}
                            {/*        </a>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            <div className='col-12 col-lg-6 mt-3 mt-lg-0'>
                                <p className='border-bottom border-gray f-16 mb-3 text-dark fw-600 footer-heading'>
                                    PRODUCT
                                </p>
                                <div className='row'>
                                    <div className='col-6 col-lg-4'>
                                        <ul className='footer-menu mt-1'>
                                            <li>
                                                <a href='/product/pricing/'>Pricing</a>
                                            </li>
                                            <li>
                                                <a href='/product/sql/'>Streaming SQL</a>
                                            </li>
                                            <li>
                                                <a href='/architecture-and-integrations/'>
                                                    Architecture
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/product/deployment/'>Deployment</a>
                                            </li>
                                            <li>
                                                <a href='/kafka-connectors/'>Connectors</a>
                                            </li>
                                            <li>
                                                <a href='/product/features/'>Features</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-6 col-lg-4'>
                                        <ul className='footer-menu mt-1'>
                                            <li>
                                                <a href='/usecases/kafka-monitoring/'>
                                                    Kafka Monitoring
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/usecases/apache-kafka-data-observability/'>
                                                    Data Exploration
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/usecases/apache-kafka-security/'>
                                                    Kafka Security
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/usecases/kafka-streams/'>Build Flows</a>
                                            </li>
                                            <li>
                                                <a href='/dataops/'>DataOps</a>
                                            </li>
                                            <li>
                                                <a href='/customers/'>Customers</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-12 d-block d-lg-none'>
                                        <hr className='mb-0 border-top border-gray' />
                                    </div>
                                    <div className='d-none d-lg-block col-lg-4'>
                                        <ul className='footer-menu mt-1'>
                                            <li>
                                                <a
                                                    href='https://docs.lenses.io'
                                                    target='_blank'
                                                    rel='noopener noreferrer'>
                                                    Documentation
                                                    <i
                                                        className='fa fa-external-link pl-2 f-10'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href='https://help.lenses.io'
                                                    target='_blank'
                                                    rel='noopener noreferrer'>
                                                    Help center
                                                    <i
                                                        className='fa fa-external-link pl-2 f-10'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/welcome/'>
                                                    Welcome area
                                                    <i
                                                        className='fa fa-heart pl-1 f-10'
                                                        aria-hidden='true'
                                                        style={{
                                                            color: '#636a78',
                                                        }}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/videos/'>Videos</a>
                                            </li>
                                            <li>
                                                <a href='/resources/#tutorials'>Tutorials</a>
                                            </li>
                                            <li>
                                                <a href='/webinars/'>Webinars</a>
                                            </li>
                                            <li>
                                                <a href='/product-sheets/'>Product sheets</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-6 d-lg-none'>
                                        <ul className='footer-menu mt-1'>
                                            <li>
                                                <a
                                                    href='https://docs.lenses.io'
                                                    target='_blank'
                                                    rel='noopener noreferrer'>
                                                    Documentation
                                                    <i
                                                        className='fa fa-external-link pl-2 f-10'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href='https://help.lenses.io'
                                                    target='_blank'
                                                    rel='noopener noreferrer'>
                                                    Help center
                                                    <i
                                                        className='fa fa-external-link pl-2 f-10'
                                                        aria-hidden='true'></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/welcome/'>
                                                    Welcome area
                                                    <i
                                                        className='fa fa-heart pl-1 f-10'
                                                        aria-hidden='true'
                                                        style={{
                                                            color: '#636a78',
                                                        }}></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-6 d-lg-none'>
                                        <ul className='footer-menu mt-1'>
                                            <li>
                                                <a href='/videos/'>Videos</a>
                                            </li>
                                            <li>
                                                <a href='/resources/#tutorials'>Tutorials</a>
                                            </li>
                                            <li>
                                                <a href='/webinars/'>Webinars</a>
                                            </li>
                                            <li>
                                                <a href='/product-sheets/'>Product sheets</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-3 mt-3 mt-lg-0'>
                                <div className='row'>
                                    <div className='col-6 col-lg-8'>
                                        <p className='border-bottom border-gray f-16 mb-3 text-dark fw-600 footer-heading'>
                                            COMPANY
                                        </p>
                                        <ul className='footer-menu mt-1'>
                                            <li>
                                                <a href='/contact-us/'>Contact us</a>
                                            </li>
                                            <li>
                                                <a href='/partners/'>Partners</a>
                                            </li>
                                            <li>
                                                <a href='/company/about-us/'>About us</a>
                                            </li>
                                            <li>
                                                <a href='/blog/'>Blog</a>
                                            </li>
                                            <li>
                                                <a href='/company/newsroom/'>Press</a>
                                            </li>
                                            <li>
                                                <a href='/company/events/'>Events</a>
                                            </li>
                                            <li>
                                                <a href='/careers/'>Careers</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-6 col-lg-4'>
                                        <p className='border-bottom border-gray f-16 mb-3 text-dark fw-600 footer-heading'>
                                            CLOUD
                                        </p>
                                        <ul className='footer-menu mt-1'>
                                            <li>
                                                <a className='text-nowrap' href='/cloud/aws-msk/'>
                                                    AWS MSK
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/cloud/azure/' className='text-nowrap'>
                                                    Azure Kafka
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/cloud/aiven/' className='text-nowrap'>
                                                    Aiven
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className='text-nowrap'
                                                    href='https://portal.lenses.io'
                                                    target='_blank'
                                                    rel='noopener noreferrer'>
                                                    Cloud portal
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-3 mb-1 mt-lg-0 border-top border-gray-darker'></div>

                    <div className='container py-1'>
                        <div className='row mb-1 align-items-center'>
                            <div className='col-12 col-sm-9 mb-1 mb-lg-0 mobile-padding-reset'>
                                <div className='d-flex flex-wrap align-items-center p-0'>
                                    <div className='col-lg-2 col-md-3 col-sm-4 col-12'>
                                        <span className='d-flex align-items-center p-0 f-14 text-nowrap'>
                                            © Lenses.io {new Date().getFullYear()}
                                        </span>
                                    </div>
                                    <div className='col-lg-10 col-sm-9 col-md-9 col-12'>
                                        <div className='d-flex flex-wrap'>
                                            <span className='d-flex align-items-center p-0'>
                                                <a
                                                    href='/legals/terms-and-conditions/'
                                                    className='p-0 f-14 mobile-padding-reset'>
                                                    Terms
                                                </a>
                                                <span className='f-14'>&nbsp;|&nbsp;</span>
                                            </span>
                                            <span className='d-flex align-items-center p-0'>
                                                <a href='/legals/policy/' className='p-0 f-14'>
                                                    Privacy
                                                </a>
                                                <span className='f-14'>&nbsp;|&nbsp;</span>
                                            </span>
                                            <span className='d-flex align-items-center p-0'>
                                                <a href='/legals/cookies/' className='p-0 f-14'>
                                                    Cookies
                                                </a>
                                                <span className='f-14'>&nbsp;|&nbsp;</span>
                                            </span>
                                            <span className='d-flex align-items-center p-0'>
                                                <a href='/legals/sla/' className='p-0 f-14'>
                                                    SLAs
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Social media links --> */}
                            <div className='col-12 col-lg-3 col-sm-2 col-md-2 col-xs-12 d-flex justify-content-sm-end justify-content-center'>
                                <ul className='footer-menu d-flex align-items-center p-0'>
                                    <li className='d-flex align-items-center mr-2 p-0'>
                                        <a
                                            title='Slack'
                                            className='d-flex align-items-center'
                                            href='https://launchpass.com/lensesio'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            <i
                                                className='fab fa-slack f-19 '
                                                aria-hidden='true'></i>
                                        </a>
                                    </li>
                                    <li className='d-flex align-items-center mr-2 p-0'>
                                        <a
                                            title='Twitter'
                                            className='d-flex align-items-center'
                                            href='https://twitter.com/lensesio'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            <i
                                                className='fab fa-twitter f-19 p-0'
                                                aria-hidden='true'></i>
                                        </a>
                                    </li>
                                    <li className='d-flex align-items-center mr-2 p-0'>
                                        <a
                                            title='Youtube'
                                            className='d-flex align-items-center'
                                            href='https://www.youtube.com/c/lensesio'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            <i
                                                className='fab fa-youtube f-19 p-0'
                                                aria-hidden='true'></i>
                                        </a>
                                    </li>
                                    <li className='d-flex align-items-center p-0'>
                                        <a
                                            title='Linkedin'
                                            className='d-flex align-items-center'
                                            href='https://linkedin.com/company/lensesio'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            <i
                                                className='fab fa-linkedin f-19 p-0 p-0'
                                                aria-hidden='true'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Apache Software Foundation section */}
                    <div className='mb-1 border-top border-gray-darker'></div>
                    <div className='container-1'>
                        <div className='d-flex flex-wrap footer-bottom'>
                            <div className='col-md-12 text-center'>
                                <p className='mb-0' style={{ lineHeight: '1.3em', color: '#aaa' }}>
                                    <a
                                        href='https://kafka.apache.org/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{ color: '#aaa' }}>
                                        Apache, Apache Kafka, Kafka{' '}
                                    </a>
                                    and associated open source project names are trademarks of the
                                    <a
                                        href='https://www.apache.org/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{ color: '#aaa' }}>
                                        Apache Software Foundation.
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Back-to-Top : Do Not Display on mobile */}
                <div id='back-to-top' className='d-none d-sm-block'>
                    <button
                        aria-label='back-to-top'
                        className='top scroll-to-top pb-5'
                        id='top'
                        onClick={scrollToTop}>
                        <i className='fa fa-angle-up'></i>
                    </button>
                </div>
            </footer>
        </Fragment>
    )
}

export default footer

footer.propTypes = {
    footerInfo: PropTypes.object,
    newsletter: PropTypes.bool,
    noFooter: PropTypes.bool,
}
