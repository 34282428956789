import React, { useEffect, Fragment } from 'react'
import Navigation from '../Navigation'

const heroSection = ({ hero }) => {
    useEffect(() => {
        try {
            // CASE HEADER 1
            if (document.getElementById('heroButton')) {
                document.getElementById('heroButton').setAttribute('data-hover', hero.ctaText)
            }
            // CASE HEADER 2

            if (document.getElementById('bg-fixed-top')) {
                if (hero.image) {
                    document.getElementById('bg-fixed-top').style.backgroundImage =
                        `url(${hero.image.fluid.src})`
                }
            }
            // console.log(navbarLayout)
            if (hero.navbarLayout == 'Transparent') {
                var nav = document.querySelector('.navbar-transparent')
                var navLinksColor = document.querySelectorAll('.nav-link-transparent')
                var navToggler = document.querySelector('.navbar-toggler')
                if (typeof window !== 'undefined') {
                    if (window.screen.width < 992) {
                        nav.classList.add('scrolled')
                        navToggler.classList.add('scrolled')
                        Array.from(navLinksColor).map((navLink) => {
                            navLink.classList.add('scrolled')
                        })
                    }
                }

                const scrolling = () => {
                    var nav = document.querySelector('.navbar-transparent')
                    var navLinksColor = document.querySelectorAll('.nav-link-transparent')
                    var navToggler = document.querySelector('.navbar-toggler')
                    if (typeof window !== 'undefined' && window.scrollY !== undefined) {
                        if (window.scrollY > 0) {
                            nav.classList.add('scrolled')
                            navToggler.classList.add('scrolled')
                            Array.from(navLinksColor).map((navLink) => {
                                navLink.classList.add('scrolled')
                            })
                        } else {
                            if (window.screen.width > 992) {
                                nav.classList.remove('scrolled')
                                navToggler.classList.remove('scrolled')
                                Array.from(navLinksColor).map((navLink) => {
                                    navLink.classList.remove('scrolled')
                                })
                            }
                        }
                    }
                }

                window.addEventListener('scroll', scrolling)
            } else if (hero.navbarLayout == 'Transparent-no-links') {
                nav = document.querySelector('.navbar-transparent')
                navLinksColor = document.querySelectorAll('.nav-link-transparent')
                if (typeof window !== 'undefined') {
                    if (window.screen.width < 992) {
                        nav.classList.add('scrolled')
                        Array.from(navLinksColor).map((navLink) => {
                            navLink.classList.add('scrolled')
                        })
                    }
                }

                const scrolling = () => {
                    var nav = document.querySelector('.navbar-transparent')
                    var navLinksColor = document.querySelectorAll('.nav-link-transparent')
                    if (typeof window !== 'undefined' && window.scrollY !== undefined) {
                        if (window.scrollY > 0) {
                            nav.classList.add('scrolled')
                            Array.from(navLinksColor).map((navLink) => {
                                navLink.classList.add('scrolled')
                            })
                        } else {
                            if (window.screen.width > 992) {
                                nav.classList.remove('scrolled')
                                Array.from(navLinksColor).map((navLink) => {
                                    navLink.classList.remove('scrolled')
                                })
                            }
                        }
                    }
                }

                window.addEventListener('scroll', scrolling)
            }
        } catch (error) {
            console.log(error)
        }
        // es-lint-disable-next-line
    }, [])

    let Hero
    let navbar
    let logo
    let logoAlt
    // NAVBAR HANDLE
    if (hero.navbarLayout === 'Transparent') {
        navbar = <Navigation linkColor={hero.navbarLinkColor} isTransparent={true} />
    } else if (hero.navbarLayout === 'Transparent-no-links') {
        navbar = <Navigation onlyLogo={true} isTransparent={true} />
    } else if (hero.navbarLayout === 'Normal') {
        navbar = <Navigation linkColor={hero.navbarLinkColor} />
    } else if (hero.navbarLayout === 'Normal-no-links') {
        navbar = <Navigation onlyLogo={true} />
    }
    if (hero.logo) {
        logo = hero.logo.fluid.src
        logoAlt = hero.logo.title
    }

    // HERO HANDLE
    if (hero.heroLayout == 'Left text, image to the right, no Background image') {
        Hero = (
            <Fragment>
                <section className='overflow-hidden hero-margin'>
                    <div className='container-1'>
                        <div className='d-flex flex-wrap'>
                            <div className='col-lg-4 col-xl-5 col-12 justify-content-center align-self-center'>
                                {logo ? (
                                    <div className='mb-3'>
                                        <img className='w-30' src={logo} alt={logoAlt} />
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div>
                                    {hero.titleStyle === 'Red' ? (
                                        <h1 className='primary-text'>{hero.title && hero.title}</h1>
                                    ) : hero.titleStyle === 'White' ? (
                                        <h1 className='text-white'>{hero.title && hero.title}</h1>
                                    ) : hero.titleStyle === 'Dark' ? (
                                        <h1>{hero.title && hero.title}</h1>
                                    ) : (
                                        ''
                                    )}

                                    <div>
                                        {hero.subtitleStyle === 'red-header' ? (
                                            <h3 className='primary-text fw-800 text-uppercase f-17'>
                                                {hero.subtitle && hero.subtitle}
                                            </h3>
                                        ) : hero.subtitleStyle === 'paragraph-title' ? (
                                            <h2 className='hero-description-dark pt-1 roboto'>
                                                {hero.subtitle && hero.subtitle}
                                            </h2>
                                        ) : hero.subtitleStyle === 'light-blue' ? (
                                            <h3 className='h4-like pt-1'>
                                                {hero.subtitle && hero.subtitle}
                                            </h3>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                {hero.ctaText && hero.ctaText ? (
                                    <div className='roll-button mt-3'>
                                        {/* Set Attribute here cuz data-hover cannot accept variable */}
                                        <a className='text-white bg-red' href={hero.ctaLink}>
                                            <span id='heroButton'>{hero.ctaText}</span>
                                        </a>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className='col-lg-8 col-xl-7 col-12 tablet-margin-top'>
                                <img
                                    className='img-fluid d-block ml-auto'
                                    style={{ marginTop: '-3px' }}
                                    src={hero.image ? hero.image.fluid.src : ''}
                                    alt={hero.image ? hero.image.title : ''}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    } else if (hero.heroLayout == 'Center text, Only Background Image') {
        Hero = (
            <Fragment>
                <section className='overflow-hidden hero-margin'>
                    <div>
                        <div className='text-center'>
                            {hero.subtitleStyle === 'red-header' ? (
                                <h2 className='primary-text f-22 pb-0 fw-800 mobile-margin-reset roboto'>
                                    {hero.subtitle && hero.subtitle}
                                </h2>
                            ) : hero.subtitleStyle === 'paragraph-title' ? (
                                <h2 className='hero-description-dark f-22 pb-0 fw-800 mobile-margin-reset roboto'>
                                    {hero.subtitle && hero.subtitle}
                                </h2>
                            ) : hero.subtitleStyle === 'light-blue' ? (
                                <h3 className='h4-like pb-0'>{hero.subtitle && hero.subtitle}</h3>
                            ) : (
                                ''
                            )}
                            <div className='row'>
                                <div className='offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 text-center'>
                                    {hero.titleStyle === 'Red' ? (
                                        <h1 className='primary-text'>{hero.title && hero.title}</h1>
                                    ) : hero.titleStyle === 'White' ? (
                                        <h1 className='text-white'>{hero.title && hero.title}</h1>
                                    ) : hero.titleStyle === 'Dark' ? (
                                        <h1>{hero.title && hero.title}</h1>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='row'>
                    <div id='bg-fixed-top' className=' col-12'></div>
                </div>
            </Fragment>
        )
    }
    return (
        <Fragment>
            {navbar}
            {Hero}
            <div className='my-4 py-4'></div>
        </Fragment>
    )
}

export default heroSection
