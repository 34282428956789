import React, { Fragment } from 'react'
import ColumnContent from './ColumnContent'

const oneColumnSection = ({ oneColumnData }) => {
    // console.log(oneColumnData)

    let oneColumnContent
    let container
    let bgColor
    let RowMargin = 0
    let textalign

    if (oneColumnData.layoutType) {
        if (oneColumnData.layoutType == 'Max') {
            container = 'container-1'
        } else if (oneColumnData.layoutType == 'Container') {
            container = 'container'
        } else if (oneColumnData.layoutType == 'Squashed') {
            container = 'container-squashed'
        }
    }

    if (oneColumnData.backgroundColourWholeRow) {
        if (oneColumnData.backgroundColourWholeRow == 'Gray') {
            bgColor = 'footer-color'
        } else if (oneColumnData.backgroundColourWholeRow == 'Red') {
            bgColor = 'bg-red'
        } else if (oneColumnData.backgroundColourWholeRow == 'Blue') {
            bgColor = 'bg-blue'
        }
    }

    if (oneColumnData.wholeRowMargin) {
        RowMargin = oneColumnData.wholeRowMargin / 2
        RowMargin = RowMargin + 'px'
        // console.log(RowMargin)
    }

    if (oneColumnData.columnAlignment) {
        if (oneColumnData.columnAlignment == 'Right') {
            textalign = 'text-right'
        } else if (oneColumnData.columnAlignment == 'Left') {
            textalign = 'text-left'
        } else if (oneColumnData.columnAlignment == 'Center') {
            textalign = 'text-center'
        }
    }

    if (oneColumnData) {
        oneColumnContent = (
            <Fragment>
                <div
                    className={bgColor && bgColor}
                    style={{
                        marginTop: `${RowMargin ? RowMargin : ''}`,
                        marginBottom: `${RowMargin ? RowMargin : ''}`,
                    }}>
                    <div className={container && container}>
                        <div className={`d-flex ${textalign ? textalign : ''}`}>
                            <div className='col-12'>
                                <ColumnContent
                                    columnContent={
                                        oneColumnData.columnContent[0] &&
                                        oneColumnData.columnContent[0]
                                    }
                                    columnAlignment={oneColumnData.columnAlignment}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return oneColumnContent
}

export default oneColumnSection
