import React from 'react'
// import lensesLogo from '../resources/images/lensesio-logo-header.png'
import lensesLogo from '../resources/images/lenses-logo.png'
import TransitionLink from 'gatsby-plugin-transition-link'

const kafkaMonitoringNavigation = ({ onlyLogo }) => {
    return (
        <header id='header-wrap'>
            <div className='navbar p-0 bg-white shadow-sm fixed-top menu-top navbar-expand-lg border-radius-0 w-100 left-0'>
                <div className='container-2 d-inline-flex flex-wrap align-items-center justify-content-between'>
                    <a
                        id='navbar-logo'
                        href='/'
                        className={`navbar-brand mr-0 pl-15 pr-2 ${
                            onlyLogo ? '' : 'border-right-navbar'
                        }`}>
                        <img
                            className='img-fluid my-2'
                            style={{ height: '37px', width: '128px' }}
                            src={lensesLogo}
                            alt='Lenses.io logo'
                        />
                    </a>
                    {!onlyLogo && (
                        <button
                            type='button'
                            className='navbar-toggler mr-2 my-1 border-0'
                            data-toggle='collapse'
                            data-target='.navbar-collapse'
                            aria-controls='.navbar-collapse'>
                            {' '}
                            <span className='sr-only'>Toggle navigation</span>
                            <span> &#x2630;</span>
                        </button>
                    )}

                    {!onlyLogo && (
                        <div className='collapse navbar-collapse navbar-collapse-monitoring-guide'>
                            <div
                                className={`${
                                    window.location.pathname.includes('intro')
                                        ? 'active-link-monitoring-nav'
                                        : ''
                                } px-2 border-right padding-kafka-monitoring-nav`}>
                                <TransitionLink
                                    id='kafka-monitoring-guide-intro'
                                    className='nav-monitoring-link mx-3 text-dark fw-300 transition-0'
                                    to='/guide/everything-need-know-about-kafka-monitoring/intro/'
                                    exit={{ length: 0.8 }}>
                                    Introduction
                                </TransitionLink>
                            </div>
                            <div
                                className={`${
                                    window.location.pathname.includes('chapter1')
                                        ? 'active-link-monitoring-nav'
                                        : ''
                                } px-2 border-right padding-kafka-monitoring-nav`}>
                                <TransitionLink
                                    id='kafka-monitoring-guide-chapter1'
                                    className='nav-monitoring-link mx-3 text-dark fw-300 transition-0'
                                    to='/guide/everything-need-know-about-kafka-monitoring/chapter1/'
                                    exit={{ length: 0.8 }}>
                                    By team
                                </TransitionLink>
                            </div>
                            <div
                                className={`${
                                    window.location.pathname.includes('chapter2')
                                        ? 'active-link-monitoring-nav'
                                        : ''
                                } px-2 border-right padding-kafka-monitoring-nav`}>
                                <TransitionLink
                                    id='kafka-monitoring-guide-chapter2'
                                    className='nav-monitoring-link mx-3 text-dark fw-300 transition-0'
                                    to='/guide/everything-need-know-about-kafka-monitoring/chapter2/'
                                    exit={{ length: 0.8 }}>
                                    By data
                                </TransitionLink>
                            </div>
                            <div
                                className={`${
                                    window.location.pathname.includes('chapter3')
                                        ? 'active-link-monitoring-nav'
                                        : ''
                                } px-2 border-right padding-kafka-monitoring-nav`}>
                                <TransitionLink
                                    id='kafka-monitoring-guide-chapter3'
                                    className='nav-monitoring-link mx-3 text-dark fw-300 transition-0'
                                    to='/guide/everything-need-know-about-kafka-monitoring/chapter3/'
                                    exit={{ length: 0.8 }}>
                                    By component
                                </TransitionLink>
                            </div>
                            <div className='px-2'>
                                <a
                                    className='nav-monitoring-link mx-3 title-text-i d-block d-lg-none'
                                    id='kafka-monitoring-guide-pdf-download'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://assets.ctfassets.net/tnuaj0t7r912/7lx6A6B8MzBayjOmKOJtau/1e2d67be765c1b2cec1c2c8223c2a283/KafkaMonitoringGuide.pdf'>
                                    Download <i className='text-right fa fa-download'></i>
                                </a>
                            </div>
                        </div>
                    )}
                    <a
                        className='title-text-i d-none d-lg-block'
                        target='_blank'
                        id='kafka-monitoring-guide-pdf-download'
                        rel='noopener noreferrer'
                        href='https://assets.ctfassets.net/tnuaj0t7r912/7lx6A6B8MzBayjOmKOJtau/1e2d67be765c1b2cec1c2c8223c2a283/KafkaMonitoringGuide.pdf'>
                        <i className='f-25-i text-right fa fa-download'></i>
                    </a>
                </div>
            </div>
        </header>
    )
}

export default kafkaMonitoringNavigation
