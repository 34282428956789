import React, { Fragment } from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import Quote from './Quote'
import { v4 as uuidv4 } from 'uuid'
import BioAuthor from './BioAuthor'
import PropTypes from 'prop-types'

const columnContent = ({ columnContent, columnAlignment }) => {
    // console.log(columnContent)

    let bgColor
    let shadow
    let rounded
    let yMargin
    let xMargin
    let xPadding
    let yPadding
    let bgImage
    let imagesWidth
    let textCentered
    let colorHeadings
    let styleList
    let elementList
    let colorText
    let sticky
    let fontSize
    let lineHeight

    // Parent variables
    if (columnAlignment == 'Right') {
        columnAlignment = 'ml-auto'
        textCentered = 'text-right'
    } else if (columnAlignment == 'Left') {
        columnAlignment = 'mr-auto'
    }

    if (columnContent.backgroundColour) {
        if (columnContent.backgroundColour == 'Gray') {
            bgColor = 'footer-color'
        } else if (columnContent.backgroundColour == 'Red') {
            bgColor = 'bg-red'
        } else if (columnContent.backgroundColour == 'Blue') {
            bgColor = 'bg-blue'
        }
    }

    if (columnContent.backgroundImage) {
        bgImage = columnContent.backgroundImage
        bgColor = null
    }

    if (columnContent.fontSize) {
        if (columnContent.fontSize == 14) {
            lineHeight = '22px'
        } else if (columnContent.fontSize == 16) {
            lineHeight = '24px'
        } else if (columnContent.fontSize == 18) {
            lineHeight = '26px'
        }
        fontSize = columnContent.fontSize + 'px'
    }

    if (columnContent.contentCentered) {
        textCentered = 'text-center'
    }

    if (columnContent.roundedEdges) {
        rounded = 'rounded'
    }

    if (columnContent.shadow) {
        shadow = 'shadow'
    }

    if (columnContent.marginY) {
        yMargin = columnContent.marginY / 2
        yMargin = yMargin + 'px'
    }
    if (columnContent.marginX) {
        xMargin = columnContent.marginX / 2
        xMargin = xMargin + 'px'
    }
    if (columnContent.paddingY) {
        yPadding = columnContent.paddingY / 2
        yPadding = yPadding + 'px'
    }
    if (columnContent.paddingX) {
        xPadding = columnContent.paddingX / 2
        xPadding = xPadding + 'px'
    }

    if (columnContent.headingsColour) {
        if (columnContent.headingsColour == 'Red') {
            colorHeadings = 'primary-text'
        } else if (columnContent.headingsColour == 'White') {
            colorHeadings = 'text-white'
        }
        if (columnContent.headingsColour == 'Dark') {
            colorHeadings = 'text-dark'
        }
        if (columnContent.headingsColour == 'Paragraph') {
            colorHeadings = 'p-text'
        }
    }

    if (columnContent.textColour) {
        if (columnContent.textColour == 'White') {
            colorText = 'text-white'
        }
        if (columnContent.textColour == 'Dark') {
            colorText = 'text-dark'
        }
        if (columnContent.textColour == 'Paragraph') {
            colorText = 'p-text'
        }
    }

    if (columnContent.listStyle) {
        if (columnContent.listStyle == 'Red arrow') {
            elementList = 'fa fa-arrow-right primary-text mx-2'
        } else if (columnContent.listStyle == 'Red checkmark') {
            styleList = 'red-checked-list'
        } else if (columnContent.listStyle == 'Yellow arrow') {
            elementList = 'fa fa-arrow-right orange-text mx-2'
        } else if (columnContent.listStyle == 'Yellow checkmark') {
            styleList = 'orange-checked-list'
        } else if (columnContent.listStyle == 'Black arrow') {
            elementList = 'fa fa-arrow-right text-dark mx-2'
        } else if (columnContent.listStyle == 'White arrow') {
            elementList = 'fa fa-arrow-right text-white mx-2'
        }
    }

    if (columnContent.isSticky) {
        sticky = 'position-sticky'
    }
    const Ηeading1 = ({ children }) => (
        <p
            className={`h1-like ${colorHeadings ? colorHeadings : ''}`}
            style={{ paddingTop: '1.7rem' }}>
            {children}
        </p>
    )
    const Ηeading2 = ({ children }) => (
        <p className={`h2-like pt-3 pb-0 mb-1 ${colorHeadings ? colorHeadings : ''}`}>{children}</p>
    )

    const Ηeading3 = ({ children }) => (
        <p
            className={`h3-like-dark mb-1 mt-5 ${colorHeadings ? colorHeadings : ''}`}
            style={{ letterSpacing: 'normal' }}>
            {children}
        </p>
    )
    const Ηeading4 = ({ children }) => (
        <h4 className={`${colorHeadings ? colorHeadings : ''}`}>{children}</h4>
    )
    const Ηeading5 = ({ children }) => (
        <h5 className={`${colorHeadings ? colorHeadings : ''}`}>{children}</h5>
    )
    const Ηeading6 = ({ children }) => (
        <h6 className={`${colorHeadings ? colorHeadings : ''}`}>{children}</h6>
    )

    const Paragraph = ({ children }) => (
        <p className={`${colorText ? colorText : ''}`}>{children}</p>
    )

    const List = ({ children }) => (
        <ul className={`${styleList ? styleList : ''}`}>
            {children.map((child) => {
                return (
                    <li key={child.key} className={`${elementList ? elementList : ''} d-flex`}>
                        <span className='pl-1'>{child.props.children[0]}</span>
                    </li>
                )
            })}
        </ul>
    )

    if (columnContent.imagesWidth) {
        if (columnContent.imagesWidth == '25%') {
            imagesWidth = 'w-25'
        } else if (columnContent.imagesWidth == '50%') {
            imagesWidth = 'w-50'
        } else if (columnContent.imagesWidth == '75%') {
            imagesWidth = 'w-75'
        } else if (columnContent.imagesWidth == '100%') {
            imagesWidth = 'w-100'
        }
    } else {
        imagesWidth = 'w-100'
    }

    const options = {
        renderMark: {
            [MARKS.CODE]: (text) => {
                return modifyText(text)
            },
        },
        renderNode: {
            // eslint-disable-next-line react/display-name
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                // Needed to not display errors Yet images won't render
                if (node.data.target.fields) {
                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: `<img src="${
                                    node.data.target.fields.file['en-US'].url
                                }" class='
                  ${imagesWidth ? imagesWidth : ''}
                  ${textCentered ? 'mx-auto' : ''}
                  ${columnAlignment ? columnAlignment : ''}
                    d-flex py-2' alt="${node.data.target.fields.title['en-US']}"/>`,
                            }}></span>
                    )
                }
            },
            // eslint-disable-next-line react/display-name
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                if (node.data.target.sys.contentType.sys.contentful_id == 'quote') {
                    return <Quote quoteData={node.data.target.fields} />
                } else if (
                    // content.data.target.sys.contentType.sys.id == 'event' ||
                    node.data.target.sys.contentType.sys.contentful_id == 'person'
                ) {
                    // console.log(content.data.target.fields)
                    return <BioAuthor authorData={node.data.target.fields} />
                } else if (node.data.target.sys.contentType.sys.contentful_id == 'event') {
                    if (node.data.target.fields.style['en-US'] == 'primary') {
                        return (
                            <div className='roll-button'>
                                <a
                                    href={node.data.target.fields.linksTo['en-US']}
                                    className='bg-red text-white text-uppercase mt-1 mr-2'>
                                    <span
                                        data-hover={`${node.data.target.fields.ctatext['en-US']}`}>
                                        {node.data.target.fields.ctatext['en-US']}
                                    </span>
                                </a>
                            </div>
                        )
                    } else if (node.data.target.fields.style['en-US'] == 'primary-outline') {
                        return (
                            <div className='roll-button'>
                                <a
                                    href={node.data.target.fields.linksTo['en-US']}
                                    className='bg-white primary-text text-uppercase mt-1 mr-2'>
                                    <span
                                        data-hover={`${node.data.target.fields.ctatext['en-US']}`}>
                                        {node.data.target.fields.ctatext['en-US']}
                                    </span>
                                </a>
                            </div>
                        )
                    } else if (node.data.target.fields.style['en-US'] == 'secondary') {
                        return (
                            <div className='roll-button'>
                                <a
                                    href={node.data.target.fields.linksTo['en-US']}
                                    className='bg-blue text-white text-uppercase mt-1 mr-2'>
                                    <span
                                        data-hover={`${node.data.target.fields.ctatext['en-US']}`}>
                                        {node.data.target.fields.ctatext['en-US']}
                                    </span>
                                </a>
                            </div>
                        )
                    } else if (node.data.target.fields.style['en-US'] == 'blue') {
                        return (
                            <div className='roll-button'>
                                <a
                                    href={node.data.target.fields.linksTo['en-US']}
                                    className='bg-light-blue text-white mt-1 mr-2'>
                                    <span
                                        data-hover={`${node.data.target.fields.ctatext['en-US']}`}>
                                        {node.data.target.fields.ctatext['en-US']}
                                    </span>
                                </a>
                            </div>
                        )
                    }
                }
            },
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_1]: (node, children) => <Ηeading1>{children}</Ηeading1>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_2]: (node, children) => <Ηeading2>{children}</Ηeading2>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_3]: (node, children) => <Ηeading3>{children}</Ηeading3>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_4]: (node, children) => <Ηeading4>{children}</Ηeading4>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_5]: (node, children) => <Ηeading5>{children}</Ηeading5>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_6]: (node, children) => <Ηeading6>{children}</Ηeading6>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.OL_LIST]: (node, children) => <List>{children}</List>,
            // eslint-disable-next-line react/display-name
            [INLINES.HYPERLINK]: (node) => {
                // console.log(node)
                if (node.data.uri.includes('player.vimeo.com/video')) {
                    return (
                        <Fragment>
                            <div className='cloud-aws-msk-video'>
                                <iframe
                                    src={node.data.uri}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    frameBorder='0'
                                    allow='autoplay; fullscreen'
                                    allowFullScreen></iframe>
                            </div>
                        </Fragment>
                    )
                } else {
                    if (columnContent.linksInNewTab) {
                        return (
                            <a
                                className='link-text'
                                target='_blank'
                                rel='noopener noreferrer'
                                href={node.data.uri}>
                                {node.content[0].value}
                            </a>
                        )
                    } else {
                        return (
                            <a className='link-text' href={node.data.uri}>
                                {node.content[0].value}
                            </a>
                        )
                    }
                }
            },
        },
    }

    function modifyText(text) {
        // console.log(text)
        return text
            .toString()
            .split(`\n`)
            .reduce((children, textSegment, index) => {
                // Replace spaces with jsSpace code
                var textSpaced = textSegment.replace(/ /g, '\xa0')
                // Single line code
                if (text.toString().split('\n').length === 1) {
                    return [
                        ...children,
                        index > 0 && <br key={uuidv4()} />,
                        // Filter for empty values
                        textSpaced !== '' ? <code>{textSpaced}</code> : null,
                    ]
                } else {
                    // Multi line codes
                    return [
                        ...children,
                        index > 0 && <pre key={uuidv4()} />,
                        // Filter for empty values
                        textSpaced !== '' ? <pre>{textSpaced}</pre> : <pre></pre>,
                    ]
                }
            }, [])
    }

    // PROPTYPES
    Ηeading1.propTypes = {
        children: PropTypes.object,
    }
    Ηeading2.propTypes = {
        children: PropTypes.object,
    }
    Ηeading3.propTypes = {
        children: PropTypes.object,
    }
    Ηeading4.propTypes = {
        children: PropTypes.object,
    }
    Ηeading5.propTypes = {
        children: PropTypes.object,
    }
    Ηeading6.propTypes = {
        children: PropTypes.object,
    }
    Paragraph.propTypes = {
        children: PropTypes.object,
    }
    List.propTypes = {
        children: PropTypes.object,
    }

    return (
        <div
            className={`${bgColor ? bgColor : ''} ${textCentered ? textCentered : ''} ${
                sticky ? sticky : ''
            } ${shadow ? shadow : ''} ${rounded ? rounded : ''}`}
            style={{
                top: '65px',
                fontSize: `${fontSize ? fontSize : '16px'}`,
                lineHeight: `${lineHeight ? lineHeight : '24px'}`,
                marginTop: `${yMargin ? yMargin : ''}`,
                marginBottom: `${yMargin ? yMargin : ''}`,
                marginLeft: `${xMargin ? xMargin : ''}`,
                marginRight: `${xMargin ? xMargin : ''}`,
                paddingBottom: `${yPadding ? yPadding : ''}`,
                paddingTop: `${yPadding ? yPadding : ''}`,
                paddingLeft: `${xPadding ? xPadding : ''}`,
                paddingRight: `${xPadding ? xPadding : ''}`,
                backgroundImage: `url(${bgImage ? bgImage.fluid.src : ''})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
            {renderRichText(columnContent.content, options)}
        </div>
    )
}

export default columnContent
