import React, { Fragment } from 'react'
import ColumnContent from './ColumnContent'

const twoColumnsSection = ({ twoColumsData }) => {
    // console.log(twoColumsData)

    let twoColumnsContent
    let container
    let bgColor
    let RowMargin = 0
    let col1
    let col2

    if (twoColumsData.layoutType) {
        if (twoColumsData.layoutType == 'Max') {
            container = 'container-1'
        } else if (twoColumsData.layoutType == 'Container') {
            container = 'container'
        } else if (twoColumsData.layoutType == 'Squashed') {
            container = 'container-squashed'
        }
    }

    if (twoColumsData.backgroundColourWholeRow) {
        if (twoColumsData.backgroundColourWholeRow == 'Gray') {
            bgColor = 'footer-color'
        } else if (twoColumsData.backgroundColourWholeRow == 'Red') {
            bgColor = 'bg-red'
        } else if (twoColumsData.backgroundColourWholeRow == 'Blue') {
            bgColor = 'bg-blue'
        }
    }

    if (twoColumsData.wholeRowMargin) {
        RowMargin = twoColumsData.wholeRowMargin / 2
        RowMargin = RowMargin + 'px'
        // console.log(RowMargin)
    }

    if (twoColumsData.columnsWidthRatio) {
        if (twoColumsData.columnsWidthRatio == '1:1') {
            col1 = 'col-sm-12 col-md-6'
            col2 = 'col-sm-12 col-md-6'
        } else if (twoColumsData.columnsWidthRatio == '2:1') {
            col1 = 'col-md-8 col-sm-12'
            col2 = 'col-md-4 col-sm-12'
        } else if (twoColumsData.columnsWidthRatio == '3:1') {
            col1 = 'col-md-9 col-sm-12'
            col2 = 'col-md-3 col-sm-12'
        } else if (twoColumsData.columnsWidthRatio == '1:2') {
            col1 = 'col-md-4 col-sm-12'
            col2 = 'col-md-8 col-sm-12'
        } else if (twoColumsData.columnsWidthRatio == '1:3') {
            col1 = 'col-md-3 col-sm-12'
            col2 = 'col-md-9 col-sm-12'
        }
    }

    if (twoColumsData) {
        twoColumnsContent = (
            <Fragment>
                <div
                    className={bgColor && bgColor}
                    style={{
                        marginTop: `${RowMargin ? RowMargin : ''}`,
                        marginBottom: `${RowMargin ? RowMargin : ''}`,
                    }}>
                    <div className={container && container}>
                        <div className='d-flex flex-wrap'>
                            <div className={`${col1 && col1} mobile-margin-bottom`}>
                                <ColumnContent
                                    columnContent={
                                        twoColumsData.columnContent[0] &&
                                        twoColumsData.columnContent[0]
                                    }
                                />
                            </div>
                            <div className={col2 && col2}>
                                <ColumnContent
                                    columnContent={
                                        twoColumsData.columnContent[1] &&
                                        twoColumsData.columnContent[1]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return twoColumnsContent
}

export default twoColumnsSection
