import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

// import lensesLogo from '../resources/images/lensesio-logo-header.png'
import lensesLogo from '../resources/images/lenses-logo.png'
import * as styles from './navigation.module.css'
import logo_451 from '../resources/images/451_page/451_Research_logo.svg'
import viseca_logo from '../resources/images/clients/viseca-logo-black.svg'

import solutions_promo_asset from '../resources/images/aws_page/hero-navbar.svg'
import awss3 from '../resources/images/S3_Connector.svg'
import askmarios from '../resources/images/nav/askmarios-banner.png'
// import start_rocket from '../resources/images/start/start-rocket.svg'
import lenses_box from '../resources/images/nav/box-nav.svg'
// import lenses_careers from '../resources/images/nav/careers-nav.svg'
import KafkaMonitoringNavigation from './kafkaMonitoringNavigation'

const navigation = ({ linkColor, hasBanner, isTransparent, logo, onlyLogo, type }) => {
    let links
    let textTransform = 'text-capitalize'

    if (linkColor) {
        let color = linkColor.toString()
        if (color === 'white') {
            links = styles.white
        } else if (color === 'dark') {
            links = styles.dark
        }
    }

    let extraLogo
    let altLogo
    if (logo === '451_logo') {
        extraLogo = logo_451
        altLogo = '451 Research Logo'
    } else if (logo === 'viseca_logo') {
        extraLogo = viseca_logo
        altLogo = 'Viseca Logo'
    }

    useEffect(() => {
        try {
            const scrolling = () => {
                if (document.querySelector('.home-banner')) {
                    var banner = document.querySelector('.home-banner')
                    var nav = document.querySelector('.navbar')
                    if (typeof window !== 'undefined' && window.scrollY !== undefined) {
                        if (window.scrollY > 48) {
                            banner.style.top = '0px'
                            nav.style.top = '40px'
                        } else {
                            banner.style.top = '5px'
                            nav.style.top = '48px'
                        }
                    }
                }
            }

            window.addEventListener('scroll', scrolling)
        } catch (err) {
            console.log(err)
        }

        // es-lint-disable-next-line
    }, [])

    if (type === 'KafkaMonitoring') {
        return (
            <Fragment>
                <KafkaMonitoringNavigation onlyLogo={onlyLogo} />
            </Fragment>
        )
    }

    if (onlyLogo) {
        return (
            <header id='header-wrap'>
                {extraLogo && extraLogo !== null ? (
                    <div
                        className='navbar navbar-transparent fixed-top menu-top navbar-expand-lg'
                        style={{ backgroundColor: `${isTransparent ? 'unset' : '#fff'}` }}>
                        <div className='container-1'>
                            <div className='navbar-header w-100 justify-content-center d-flex py-3'>
                                <a id='navbar-logo' href='/' className='navbar-brand mr-0'>
                                    <img
                                        className='img-fluid'
                                        style={{ height: '37px', width: '128px' }}
                                        src={lensesLogo}
                                        alt='Lenses.io logo'
                                    />
                                </a>
                                <img
                                    className='img-fluid border_451'
                                    style={{ height: '37px', width: '128px' }}
                                    src={extraLogo}
                                    alt={altLogo}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className='navbar navbar-transparent fixed-top menu-top navbar-expand-lg'
                        style={{ backgroundColor: `${isTransparent ? 'unset' : '#fff'}` }}>
                        <div className='container-1'>
                            <div className='mx-auto d-flex justify-content-center mobile-padding-reset py-3'>
                                <a id='navbar-logo' href='/' className='navbar-brand mr-0'>
                                    <img
                                        className='img-fluid'
                                        style={{ height: '37px', width: '128px' }}
                                        src={lensesLogo}
                                        alt='Lenses.io logo'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </header>
        )
    }

    return (
        <header id='header-wrap'>
            <div
                className={`navbar p-0 ${
                    isTransparent ? 'navbar-transparent' : 'bg-white shadow-sm'
                } fixed-top menu-top navbar-expand-lg`}
                style={
                    hasBanner &&
                    hasBanner === true && {
                        top: '48px',
                    }
                }
                id={`${hasBanner ? 'home-banner' : ''}`}>
                <div
                    className='container-1 d-flex flex-wrap align-items-center justify-content-between'
                    style={{ backgroundColor: `${isTransparent ? 'unset' : '#fff'}` }}>
                    <div className='navbar-header align-self-center justify-content-center'>
                        <a id='navbar-logo' href='/' className='navbar-brand'>
                            <img
                                style={{ height: '37px', width: '128px' }}
                                src={lensesLogo}
                                alt='Lenses.io logo'
                            />
                        </a>
                    </div>
                    <button
                        type='button'
                        className='navbar-toggler mr-2 my-1 border-0'
                        data-toggle='collapse'
                        data-target='.navbar-collapse'
                        aria-controls='.navbar-collapse'>
                        {' '}
                        <span className='sr-only'>Toggle navigation</span>
                        <span> &#x2630;</span>
                    </button>

                    <div className='collapse navbar-collapse'>
                        <nav className='mx-auto' role='navigation'>
                            <div className='d-lg-flex flex-wrap justify-content-between pb-0'>
                                <div className='dropdown nav-item mobile-margin-top-half'>
                                    <a
                                        id='navbar-Product'
                                        href='#'
                                        className='nav-link cursor-default lh-30'
                                        data-toggle='dropdown'
                                        role='button'
                                        aria-haspopup='true'
                                        aria-expanded='false'>
                                        <span
                                            className={`nav-link-transparent ${links} ${textTransform}`}>
                                            Products
                                        </span>
                                    </a>
                                    <div className='dropdown-menu nav-col-product  border-0'>
                                        <div className='grid-container-navbar-product h-100'>
                                            <div className='border-right-navbar ml-4'>
                                                <p className='text-uppercase f-12'>
                                                    <u>Developer Experience</u>
                                                </p>
                                                <a id='kafka-devx' href='/why-kafka-devx/'>
                                                    Why Kafka DevX?
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        3 benefits of using Lenses
                                                    </p>
                                                </a>
                                                <a id='devx-features' href='/product/features/'>
                                                    DevX Features
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        Features developers love
                                                    </p>
                                                </a>
                                            </div>

                                            <div
                                                className='mobile-margin-left-navbar border-right-navbar'
                                                style={{ paddingRight: '1rem' }}>
                                                <p className='text-uppercase f-12'>
                                                    <u>Kafka Connectors</u>
                                                </p>
                                                <a
                                                    id='enterprise-connectors'
                                                    href='/kafka-connectors/'>
                                                    Kafka Connectors
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        Source and sink
                                                    </p>
                                                </a>
                                                <a
                                                    id='open-source-connectors'
                                                    href='/enterprise-support/'
                                                    target='_blank'
                                                    rel='noreferrer'>
                                                    Enterprise Support
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',

                                                            marginBottom: 0,
                                                        }}>
                                                        Feature-packed, SLAs covered
                                                    </p>
                                                </a>
                                                <a
                                                    id='open-source-connectors'
                                                    href='https://github.com/lensesio/stream-reactor'
                                                    target='_blank'
                                                    rel='noreferrer'>
                                                    Open-Source Connectors
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        Apache 2.0 licensed
                                                    </p>
                                                </a>
                                            </div>
                                            <div className='mobile-margin-left-navbar'>
                                                <p className='text-uppercase f-12'>
                                                    <u>HOW IT WORKS</u>
                                                </p>
                                                <a
                                                    id='architecture'
                                                    href='/architecture-and-integrations/'>
                                                    Architecture
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        Integrate existing systems
                                                    </p>
                                                </a>
                                                <a id='streaming-sql' href='/product/sql/'>
                                                    Streaming SQL
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        Low-code Kafka streams
                                                    </p>
                                                </a>
                                                <a id='deployment' href='/product/deployment/'>
                                                    Deployment
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        Any Kafka, any cloud
                                                    </p>
                                                </a>
                                                <a id='deployment' href='/dataops/'>
                                                    DataOps
                                                    <p
                                                        className='f-12 light-gray'
                                                        style={{
                                                            color: '#666',
                                                            fontWeight: 'lighter',
                                                            marginBottom: 0,
                                                        }}>
                                                        Operational best practices
                                                    </p>
                                                </a>
                                            </div>

                                            <div className='mr-4 d-none d-sm-block align-self-center'>
                                                <a
                                                    className='text-center'
                                                    style={{ paddingLeft: '20px' }}
                                                    id='navbar-solutions-promo-asset'
                                                    href='/kafka-connectors/kafka-to-aws-s3/'>
                                                    <img src={awss3} alt='Promo asset' />
                                                    <p className='mt-2 mb-0 fw-900 f-16'>
                                                        Try our newest <br /> connector for <br />{' '}
                                                        Amazon S3
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='dropdown nav-item'>
                                    <a
                                        id='pricing'
                                        href='/product/pricing/'
                                        className='nav-link cursor-default lh-30'
                                        // data-toggle='dropdown'
                                        role='button'
                                        aria-haspopup='true'
                                        aria-expanded='false'>
                                        <span
                                            className={`nav-link-transparent ${links} ${textTransform}`}>
                                            Pricing
                                        </span>
                                    </a>
                                </div>

                                <div className='dropdown nav-item'>
                                    <a
                                        id='navbar-solutions'
                                        href='#'
                                        className='nav-link cursor-default lh-30'
                                        data-toggle='dropdown'
                                        role='button'
                                        aria-haspopup='true'
                                        aria-expanded='false'>
                                        <span
                                            className={`nav-link-transparent ${links} ${textTransform}`}>
                                            Solutions
                                        </span>
                                    </a>
                                    <div className='dropdown-menu nav-col-solutions  border-0'>
                                        <div className='grid-container-navbar-solutions h-100'>
                                            <div className='border-right-navbar ml-4'>
                                                <p className='text-uppercase f-12'>
                                                    <u>By cloud</u>
                                                </p>
                                                <a id='navbar-product-aws' href='/cloud/aws-msk/'>
                                                    AWS MSK
                                                    {/* <img
                            className="w-10 logo-gray ml-4"
                            src={amazon_web_services}
                            alt="AWS MSK"
                          /> */}
                                                </a>
                                                <a id='navbar-product-azure' href='/cloud/azure/'>
                                                    Azure HDInsight
                                                </a>
                                                <a id='navbar-product-aiven' href='/cloud/aiven/'>
                                                    Aiven
                                                </a>
                                                <a
                                                    id='navbar-product-confluent'
                                                    href='https://help.lenses.io/quickstart/managed-kafka/confluent-cloud/'>
                                                    Confluent
                                                </a>
                                                <a id='navbar-product-other-clouds' href='/cloud/'>
                                                    Other clouds
                                                </a>
                                            </div>

                                            <div className='border-right-navbar mobile-margin-left-navbar'>
                                                <p className='text-uppercase f-12'>
                                                    <u>By industry</u>
                                                </p>
                                                <a
                                                    href='/apache-kafka-for-finance/'
                                                    id='navbar-solutions-kafka-finance'>
                                                    Kafka for finance
                                                </a>
                                                <a
                                                    href='/apache-kafka-for-healthcare/'
                                                    id='navbar-solutions-kafka-healthcare'>
                                                    Kafka for healthcare
                                                </a>
                                                <a
                                                    href='/customers/article-wins-marketshare-using-apache-kafka/'
                                                    id='navbar-solutions-kafka-eCommerce'>
                                                    Kafka for eCommerce
                                                </a>
                                                <a
                                                    href='/customers/vortexa/'
                                                    id='navbar-solutions-kafka-maritime'>
                                                    Kafka for maritime
                                                </a>
                                                <a
                                                    href='/customers/playtika/'
                                                    id='navbar-solutions-kafka-gaming'>
                                                    Kafka for gaming
                                                </a>
                                            </div>

                                            <div className='mobile-margin-left-navbar'>
                                                <p className='text-uppercase f-12'>
                                                    <u>By use case</u>
                                                </p>
                                                <a
                                                    href='/usecases/kafka-monitoring/'
                                                    id='navbar-usecases-monitor'>
                                                    Kafka monitoring
                                                </a>
                                                <a
                                                    href='/usecases/apache-kafka-data-observability/'
                                                    id='navbar-usecases-explore'>
                                                    Data observability & catalog
                                                </a>
                                                <a
                                                    href='/usecases/apache-kafka-governance/'
                                                    id='navbar-usecases-govern'>
                                                    Kafka self-service & governance
                                                </a>
                                                <a
                                                    href='/usecases/apache-kafka-security/'
                                                    id='navbar-usecases-secure'>
                                                    Kafka security
                                                </a>
                                                <a
                                                    href='/usecases/kafka-streams/'
                                                    id='navbar-usecases-build'>
                                                    Low-code Kafka streams
                                                </a>
                                            </div>

                                            <div className='mr-4 d-none d-sm-block align-self-center'>
                                                <a
                                                    className='text-center'
                                                    style={{ paddingLeft: '20px' }}
                                                    id='navbar-solutions-promo-asset'
                                                    href='/cloud/aws-msk/'>
                                                    <img
                                                        src={solutions_promo_asset}
                                                        alt='Promo asset'
                                                    />
                                                    <p className='mt-2 mb-0 fw-900 f-16'>AWS MSK</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='dropdown nav-item'>
                                    <a
                                        id='navbar-learn'
                                        href='#'
                                        className='nav-link cursor-default lh-30'
                                        data-toggle='dropdown'
                                        role='button'
                                        aria-haspopup='true'
                                        aria-expanded='false'>
                                        <span
                                            className={`nav-link-transparent ${links} ${textTransform}`}>
                                            Learn
                                        </span>
                                    </a>
                                    <div className='dropdown-menu nav-col-learn  border-0'>
                                        <div className=' grid-container-navbar-learn h-100'>
                                            <div className='border-right-navbar ml-4'>
                                                <p className='text-uppercase f-12'>
                                                    <u>For Developers</u>
                                                </p>
                                                <a
                                                    href='/what-is-apache-kafka/'
                                                    id='what-is-apache-kafka'>
                                                    What is Apache Kafka?
                                                </a>
                                                <a
                                                    href='https://help.lenses.io/'
                                                    id='navbar-learn-help'>
                                                    Help
                                                </a>
                                                <a
                                                    href='https://docs.lenses.io/'
                                                    id='navbar-product-documentation'>
                                                    Docs
                                                </a>
                                                <a href='/resources/' id='navbar-company-resources'>
                                                    Resources
                                                </a>
                                                <a href='/blog/' id='navbar-company-blog'>
                                                    Blog
                                                </a>
                                                <a
                                                    href='https://api.lenses.io/'
                                                    id='navbar-learn-api'>
                                                    API
                                                </a>
                                            </div>

                                            <div className='mobile-margin-left-navbar'>
                                                <p className='text-uppercase f-12'>
                                                    <u>Tools</u>
                                                </p>
                                                <a
                                                    href='/cloud/managed-kafka-comparison-guide/'
                                                    id='navbar-learn-cloud-comparison'>
                                                    Kafka cloud comparison guide
                                                </a>

                                                <a
                                                    href='/kafka-roi-calculator/'
                                                    id='navbar-learn-kafka-roi'>
                                                    Kafka ROI calculator
                                                </a>

                                                <a
                                                    href='/resources/streaming-sql-cheat-sheet-for-apache-kafka/'
                                                    id='navbar-learn-sql-cheatsheet'>
                                                    SQL cheatsheet for Kafka
                                                </a>
                                            </div>
                                            <div className='mr-4 d-none d-sm-block'>
                                                <a
                                                    className='text-center'
                                                    style={{ paddingLeft: '25px' }}
                                                    id='navbar-learn-promo-asset'
                                                    href='/apache-kafka-docker/'>
                                                    <img
                                                        className='img-fluid w-75'
                                                        src={lenses_box}
                                                        alt='Lenses box'
                                                    />
                                                    <p className='mt-2 mb-0 fw-900 f-16'>
                                                        Free Kafka Docker Box
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='dropdown nav-item'>
                                    <a
                                        id='navbar-community'
                                        href='#'
                                        className='nav-link cursor-default lh-30'
                                        data-toggle='dropdown'
                                        role='button'
                                        aria-haspopup='true'
                                        aria-expanded='false'>
                                        <span
                                            className={`nav-link-transparent ${links} ${textTransform}`}>
                                            Community
                                        </span>
                                    </a>
                                    <div className='dropdown-menu nav-col-community  border-0'>
                                        <div className=' grid-container-navbar-community h-100'>
                                            <div className='border-right-navbar ml-4 pt-4'>
                                                <a
                                                    className='align-items-center d-flex flex-wrap'
                                                    href='https://ask.lenses.io'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    id='navbar-community-askmarios'>
                                                    <span className='f-30'>
                                                        <i
                                                            aria-hidden='true'
                                                            className='fab fa-discourse mr-3'></i>
                                                    </span>
                                                    Ask Marios
                                                </a>
                                                <a
                                                    className='align-items-center d-flex flex-wrap'
                                                    href='https://github.com/lensesio'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    id='navbar-community-github'>
                                                    <span className='f-30'>
                                                        <i
                                                            aria-hidden='true'
                                                            className='fab fa-github mr-3'></i>
                                                    </span>
                                                    Github
                                                </a>
                                                <a
                                                    className='align-items-center d-flex flex-wrap'
                                                    href='https://www.linkedin.com/company/lensesio'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    id='navbar-community-linkedin'>
                                                    <span className='f-30'>
                                                        <i
                                                            aria-hidden='true'
                                                            className='fab fa-linkedin mr-3'></i>
                                                    </span>
                                                    <span className='pt-2'>LinkedIn</span>
                                                </a>
                                                <a
                                                    className='align-items-center d-flex flex-wrap'
                                                    href='https://launchpass.com/lensesio'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    id='navbar-community-slack'>
                                                    <span className='f-30'>
                                                        <i
                                                            aria-hidden='true'
                                                            className='fab fa-slack mr-3'></i>
                                                    </span>
                                                    Slack
                                                </a>
                                                <a
                                                    className='align-items-center d-flex flex-wrap'
                                                    href='https://twitter.com/lensesio'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    id='navbar-community-twitter'>
                                                    <span className='f-30'>
                                                        <i
                                                            aria-hidden='true'
                                                            className='fab fa-twitter mr-3'></i>
                                                    </span>
                                                    Twitter
                                                </a>

                                                <a
                                                    className='align-items-center d-flex flex-wrap'
                                                    href='https://www.youtube.com/channel/UCWHoDtkhWPswP5KIY6mq7RQ'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    id='navbar-community-youtube'>
                                                    <span className='f-25'>
                                                        <i
                                                            aria-hidden='true'
                                                            className='fab fa-youtube mr-3'></i>
                                                    </span>
                                                    <span>YouTube</span>
                                                </a>
                                            </div>

                                            <div className='mobile-margin-left-navbar pt-4 mobile-padding-reset'>
                                                <a
                                                    href='/company/about-us/'
                                                    id='navbar-company-about'>
                                                    About us
                                                </a>

                                                <a href='/customers/' id='navbar-customers'>
                                                    Customers
                                                </a>

                                                <a href='/partners/' id='navbar-company-partners'>
                                                    Partners
                                                </a>
                                                <a
                                                    href='/company/newsroom/'
                                                    id='navbar-company-newsroom'>
                                                    Newsroom
                                                </a>
                                                <a
                                                    id='navbar-community-events'
                                                    href='/company/events'>
                                                    Events
                                                </a>
                                                <a id='navbar-community-events' href='/careers/'>
                                                    Careers
                                                </a>
                                                <a
                                                    id='navbar-product-support'
                                                    href='/product/support/'>
                                                    Support
                                                </a>
                                                <a
                                                    href='/contact-us/'
                                                    id='navbar-company-contactUs'>
                                                    Contact us
                                                </a>
                                            </div>

                                            <div className='mr-4 d-none d-sm-block'>
                                                <a
                                                    className='text-center'
                                                    style={{
                                                        paddingLeft: '30px',
                                                        paddingTop: '60px',
                                                    }}
                                                    id='navbar-community-promo-asset'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://ask.lenses.io'>
                                                    <img
                                                        className='img-fluid w-75'
                                                        src={askmarios}
                                                        alt='Ask Marios banner'
                                                    />
                                                    <p className='mt-4 mb-0 fw-900 f-16'>
                                                        The all-new <br /> Lenses Community
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className='dropdown nav-item mobile-margin-reset'>*/}
                                {/*    <a*/}
                                {/*        className='nav-link lh-30 cursor-default'*/}
                                {/*        data-toggle='dropdown'*/}
                                {/*        role='button'*/}
                                {/*        aria-haspopup='true'*/}
                                {/*        aria-expanded='false'*/}
                                {/*        id='navbar-company'>*/}
                                {/*        <span*/}
                                {/*            className={`nav-link-transparent ${links} ${textTransform}`}>*/}
                                {/*            Company*/}
                                {/*        </span>*/}
                                {/*    </a>*/}
                                {/*    <div className=' dropdown-menu nav-col-company border-0'>*/}
                                {/*        <div className=' grid-container-navbar-company h-100'>*/}
                                {/*            <div className='ml-4  pt-4'>*/}
                                {/*                <a*/}
                                {/*                    href='/company/about-us/'*/}
                                {/*                    id='navbar-company-about'>*/}
                                {/*                    About us*/}
                                {/*                </a>*/}

                                {/*                <a href='/customers/' id='navbar-customers'>*/}
                                {/*                    Customers*/}
                                {/*                </a>*/}

                                {/*                <a href='/partners/' id='navbar-company-partners'>*/}
                                {/*                    Partners*/}
                                {/*                </a>*/}
                                {/*                <a*/}
                                {/*                    href='/company/newsroom/'*/}
                                {/*                    id='navbar-company-newsroom'>*/}
                                {/*                    Newsroom*/}
                                {/*                </a>*/}
                                {/*                <a*/}
                                {/*                    href='/contact-us/'*/}
                                {/*                    id='navbar-company-contactUs'>*/}
                                {/*                    Contact us*/}
                                {/*                </a>*/}
                                {/*            </div>*/}
                                {/*            <div className='mr-4 d-none d-sm-block grid-bg'>*/}
                                {/*                <a*/}
                                {/*                    className='text-center'*/}
                                {/*                    id='navbar-company-promo-asset'*/}
                                {/*                    href='/careers/'>*/}
                                {/*                    <img*/}
                                {/*                        className='img-fluid careers-img-nav'*/}
                                {/*                        src={lenses_careers}*/}
                                {/*                        alt='Lenses Careers'*/}
                                {/*                    />*/}
                                {/*                    <p className='mt-4 mb-0 fw-900 f-16'>Careers</p>*/}
                                {/*                </a>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </nav>
                        {/*<div className='d-flex justify-content-sm-center justify-content-start'>*/}
                        {/*    <a*/}
                        {/*        id='navbar-login'*/}
                        {/*        href='https://portal.lenses.io/login'*/}
                        {/*        className='nav-link d-none d-lg-block'>*/}
                        {/*        <span className={`${textTransform} login-navbar fw-400`}>*/}
                        {/*            Login*/}
                        {/*        </span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        <div className='float-right roll-button navbar-dl-button mobile-padding-left mobile-margin-top-half'>
                            <a
                                id='navbar-download'
                                style={{ fontSize: '1rem', backgroundColor: '#ff9448' }}
                                className='text-white mr-0 bg-red'
                                href='/start/'>
                                <span data-hover='Try it'>Try it</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default navigation

navigation.propTypes = {
    linkColor: PropTypes.string,
    hasBanner: PropTypes.bool,
    isTransparent: PropTypes.bool,
    logo: PropTypes.string,
    onlyLogo: PropTypes.bool,
}
