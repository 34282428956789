import React, { Fragment } from 'react'

const bioAuthor = ({ authorData }) => {
    // console.log(authorData)
    // console.log(authorData.image['en-US'])
    return (
        <Fragment>
            <div className='bg-white rounded bio-author-space'></div>
            <div className='d-flex flex-wrap my-5'>
                {authorData.bio ? (
                    <Fragment>
                        <div className='col-5'>
                            <img
                                className='author-photo mr-3 gatsby-image-wrapper'
                                style={{ width: '60px', height: '60px' }}
                                src={
                                    authorData && authorData.image['en-US'].fields.file['en-US'].url
                                }
                                alt={authorData && authorData.image['en-US'].fields.title}
                            />
                        </div>
                        <div className='col-7'>
                            {authorData && authorData.name['en-US']}
                            <div className='primary-text'>
                                {' '}
                                {authorData && authorData.title['en-US']}{' '}
                            </div>
                        </div>
                        {authorData.bio && authorData.bio['en-US']}
                    </Fragment>
                ) : (
                    <div className='col-12 text-center'>
                        <div>
                            <img
                                className='author-photo mx-auto gatsby-image-wrapper'
                                style={{ width: '60px', height: '60px' }}
                                src={
                                    authorData && authorData.image['en-US'].fields.file['en-US'].url
                                }
                                alt={authorData && authorData.image['en-US'].fields.title}
                            />
                        </div>
                        <div className='f-12'>
                            <b>{authorData && authorData.name['en-US']}</b>
                        </div>
                        <b>
                            <div className='primary-text f-12'>
                                {' '}
                                {authorData && authorData.title['en-US']}{' '}
                            </div>
                        </b>
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default bioAuthor
