import React, { Fragment } from 'react'
import ColumnContent from './ColumnContent'

const fourColumnsSection = ({ fourColumsData }) => {
    // console.log(fourColumsData)

    let fourColumnsContent
    let container
    let bgColor
    let RowMargin = 0

    if (fourColumsData.layoutType) {
        if (fourColumsData.layoutType == 'Max') {
            container = 'container-1'
        } else if (fourColumsData.layoutType == 'Container') {
            container = 'container'
        } else if (fourColumsData.layoutType == 'Squashed') {
            container = 'container-squashed'
        }
    }

    if (fourColumsData.backgroundColourWholeRow) {
        if (fourColumsData.backgroundColourWholeRow == 'Gray') {
            bgColor = 'footer-color'
        } else if (fourColumsData.backgroundColourWholeRow == 'Red') {
            bgColor = 'bg-red'
        } else if (fourColumsData.backgroundColourWholeRow == 'Blue') {
            bgColor = 'bg-blue'
        }
    }

    if (fourColumsData.wholeRowMargin) {
        RowMargin = fourColumsData.wholeRowMargin / 2
        RowMargin = RowMargin + 'px'
        // console.log(RowMargin)
    }

    if (fourColumsData) {
        fourColumnsContent = (
            <Fragment>
                <div
                    className={bgColor && bgColor}
                    style={{
                        marginTop: `${RowMargin ? RowMargin : ''}`,
                        marginBottom: `${RowMargin ? RowMargin : ''}`,
                    }}>
                    <div className={container && container}>
                        <div className='d-flex flex-wrap'>
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom'>
                                <ColumnContent
                                    columnContent={
                                        fourColumsData.columnContent[0] &&
                                        fourColumsData.columnContent[0]
                                    }
                                />
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom'>
                                <ColumnContent
                                    columnContent={
                                        fourColumsData.columnContent[1] &&
                                        fourColumsData.columnContent[1]
                                    }
                                />
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom'>
                                <ColumnContent
                                    columnContent={
                                        fourColumsData.columnContent[2] &&
                                        fourColumsData.columnContent[2]
                                    }
                                />
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                                <ColumnContent
                                    columnContent={
                                        fourColumsData.columnContent[3] &&
                                        fourColumsData.columnContent[3]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return fourColumnsContent
}

export default fourColumnsSection
