import React, { Fragment } from 'react'
// import quotes from '../../resources/images/graphics/quotes.svg'
import quotes from '../../resources/images/Homepage/quotes.svg'

const quote = ({ quoteData }) => {
    // console.log(quoteData)
    let quote
    let quoteLayout = quoteData.quoteLayout['en-US']
    // let title = quoteData.title['en-US']
    let jobTitle = quoteData.jobTitle['en-US']
    let person = quoteData.person['en-US']
    let quoteText = quoteData.quoteText['en-US']

    if (quoteLayout == 'Type1') {
        quote = (
            <Fragment>
                <div className='bg-white text-left p-4 position-relative'>
                    {/* <h3 className="text-dark my-2">{title}</h3> */}
                    <p className='f-20 quote-line-height'>
                        <i> {quoteText} </i>
                    </p>
                    <p className='primary-text fw-600'>
                        <i>{`${person}, ${jobTitle}`}</i>
                    </p>
                    <div
                        style={{
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                            width: '150px',
                            zIndex: '1',
                        }}>
                        <img src={quotes} alt='quotes' />
                    </div>
                </div>
            </Fragment>
        )
    } else if (quoteData.quoteLayout == 'Type2') {
        console.log('Type2')
        quote = 'type2'
    } else if (quoteData.quoteLayout == 'Type3') {
        console.log('Type3')
        quote = 'type3'
    }

    return quote
}

export default quote
