import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React, { Fragment } from 'react'

import Spacing from '../partials/Spacing'

const threeBoxSection = ({ threeBoxData }) => {
    // console.log(threeBoxData)

    let threeBoxContent

    if (threeBoxData) {
        threeBoxContent = (
            <Fragment>
                <Spacing />
                <section>
                    <div className='container-1'>
                        <div className='d-flex flex-wrap'>
                            <div className='col-md col-sm-6 col-12 mt-1'>
                                <div className='home-three-items-box border-top-0 rounded-1'>
                                    <img
                                        className='w-100 rounded border-right border-left  '
                                        src={threeBoxData.firstBoxImage.fluid.src}
                                        alt={threeBoxData.firstBoxImage.title}
                                    />
                                    <div className='py-4 px-3'>
                                        <h4 className='mb-1'>{threeBoxData.firstBoxTitle}</h4>
                                        <p className='cera-pro'>
                                            {renderRichText(threeBoxData.firstBoxDescription)}
                                        </p>
                                        <a
                                            className='primary-text home-link-box'
                                            href={threeBoxData.firstBoxCtaLink}>
                                            {threeBoxData.firstBoxCtaText}{' '}
                                            <i className='red-pointer-right'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md col-sm-6 col-12 mt-1'>
                                <div className='home-three-items-box border-top-0 rounded-1'>
                                    <img
                                        className='w-100 rounded'
                                        src={threeBoxData.secondBoxImage.fluid.src}
                                        alt={threeBoxData.secondBoxImage.title}
                                    />
                                    <div className='py-4 px-3'>
                                        <h4 className='mb-1'>{threeBoxData.secondBoxTitle}</h4>
                                        <p className='cera-pro'>
                                            {renderRichText(threeBoxData.secondBoxDescription)}
                                        </p>
                                        <a
                                            className='primary-text home-link-box'
                                            href={threeBoxData.secondBoxCtaLink}>
                                            {threeBoxData.secondBoxCtaText}{' '}
                                            <i className='red-pointer-right'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md col-sm-6 col-12 mt-1'>
                                <div className='home-three-items-box border-top-0 rounded-1'>
                                    <img
                                        className='w-100 rounded'
                                        src={threeBoxData.thirdBoxImage.fluid.src}
                                        alt={threeBoxData.thirdBoxImage.title}
                                    />
                                    <div className='py-4 px-3'>
                                        <h4 className='mb-1'>{threeBoxData.thirdBoxTitle}</h4>
                                        <p className='cera-pro'>
                                            {renderRichText(threeBoxData.thirdBoxDescription)}
                                        </p>
                                        <a
                                            className='primary-text home-link-box'
                                            href={threeBoxData.thirdBoxCtaLink}>
                                            {threeBoxData.thirdBoxCtaText}
                                            <i className='red-pointer-right'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
            </Fragment>
        )
    }

    return threeBoxContent
}

export default threeBoxSection
