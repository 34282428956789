import React, { Fragment } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import Spacing from '../partials/Spacing'
import { v4 as uuidv4 } from 'uuid'

const formSection = ({ formData }) => {
    //
    //
    // FORM SECTION IS DEPRECATED SINCE IT IS BASED ON ZOHO CRM
    // CURRENTLY USING SALESFORCE
    // SO JUST DISABLING LINT RULES
    //
    let form
    // eslint-disable-next-line
    const Ηeading1 = ({ children }) => <h1 id="heading-title">{children}</h1>
    // eslint-disable-next-line
    const UlList = ({ children }) => (
        <ul id='heading-title' className='red-checked-list'>
            {/* eslint-disable-next-line */}
            {children.map((child, index) => {
                return (
                    <li className='d-flex' key={uuidv4()}>
                        {/* FOR ARROWS INSTEAD OF CHECKS UNCOMMENT BELOW AND REMOVE UL CLASS "red-checked-list" ALSO ADD "list-style-type:none" */}
                        {/* <i className="fa fa-arrow-right contact-us-arrow pt-1" aria-hidden="true"></i> */}
                        {child.props.children[0]}
                    </li>
                )
            })}
        </ul>
    )

    function modifyText(text) {
        // console.log(text)
        return text
            .toString()
            .split(`\n`)
            .reduce((children, textSegment, index) => {
                // Replace spaces with jsSpace code
                var textSpaced = textSegment.replace(/ /g, '\xa0')
                // Single line code
                if (text.toString().split('\n').length === 1) {
                    return [
                        ...children,
                        index > 0 && <br key={uuidv4()} />,
                        // Filter for empty values
                        textSpaced !== '' ? <code key={uuidv4()}>{textSpaced}</code> : null,
                    ]
                } else {
                    // Multi line codes
                    return [
                        ...children,
                        index > 0 && <pre key={uuidv4()} />,
                        // Filter for empty values
                        textSpaced !== '' ? (
                            <pre key={uuidv4()}>{textSpaced}</pre>
                        ) : (
                            <pre key={uuidv4()}></pre>
                        ),
                    ]
                }
            }, [])
    }

    const optionsForm = {
        renderMark: {
            [MARKS.CODE]: (text) => {
                return modifyText(text)
            },
        },
        renderNode: {
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_1]: (node, children) => <Ηeading1>{children}</Ηeading1>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.UL_LIST]: (node, children) => {
                return <UlList>{children}</UlList>
            },
            // eslint-disable-next-line react/display-name
            [BLOCKS.PARAGRAPH]: (node, children) => <p className='p-text py-0'>{children}</p>,
            // [BLOCKS.HEADING_2]: (node, children) => <Ηeading2>{children}</Ηeading2>,
            // [BLOCKS.HEADING_3]: (node, children) => <Ηeading3>{children}</Ηeading3>,
            // Rest of cases
            // HEADING_3
            // HEADING_4
            // HEADING_5
            // HEADING_6
            // eslint-disable-next-line react/display-name
            [INLINES.HYPERLINK]: (node) => {
                return (
                    <a href={node.data.uri} className='link-text'>
                        {node.content[0].value}
                    </a>
                )
            },
        },
    }

    if (formData) {
        let hasName = false
        let hasEmail = false
        let hasCompany = false
        let hasJobTitle = false
        let hasCountry = false
        let hasTelephone = false
        let hasTextMessage = false

        if (formData.formFields.length > 0) {
            formData.formFields.map((formField) => {
                if (formField === 'Name') {
                    hasName = true
                } else if (formField === 'Email') {
                    hasEmail = true
                } else if (formField === 'Company') {
                    hasCompany = true
                } else if (formField === 'JobTitle') {
                    hasJobTitle = true
                } else if (formField === 'Country') {
                    hasCountry = true
                } else if (formField === 'Telephone') {
                    hasTelephone = true
                } else if (formField === 'Text-Message') {
                    hasTextMessage = true
                }
            })
        }

        let nameRequired = false
        let emailRequired = false
        let companyRequired = false
        let jobTitleRequired = false
        let countryRequired = false
        let telephoneRequired = false
        let textMessageRequired = false

        if (formData.requiredFormFields.length > 0) {
            formData.requiredFormFields.map((requiredFormField) => {
                if (requiredFormField === 'Name') {
                    nameRequired = true
                } else if (requiredFormField === 'Email') {
                    emailRequired = true
                } else if (requiredFormField === 'Company') {
                    companyRequired = true
                } else if (requiredFormField === 'JobTitle') {
                    jobTitleRequired = true
                } else if (requiredFormField === 'Country') {
                    countryRequired = true
                } else if (requiredFormField === 'Telephone') {
                    telephoneRequired = true
                } else if (requiredFormField === 'Text-Message') {
                    textMessageRequired = true
                }
            })
        }

        const onChange = () => {
            if (formData.buttonId) {
                document.getElementById(formData.buttonId).disabled = false
            } else {
                document.getElementById('formsubmit').disabled = false
            }
        }

        const onClick = () => {
            // document.getElementById('LEADCF27').value = getTimeStamp()
            if (formData.buttonId) {
                document.getElementById(formData.buttonId).disabled = true
            } else {
                document.getElementById('formsubmit').disabled = true
            }
        }
        form = (
            <Fragment>
                <section>
                    <div className='container-1'>
                        <div className='d-flex flex-wrap'>
                            <div className='col-lg-5 col-xl-6 col-12 col-md-12 justify-content-center align-self-center'>
                                <div>
                                    {formData.leftColumnImage ? (
                                        <img
                                            className='w-75'
                                            src={formData.leftColumnImage.fluid.src}
                                            alt={formData.leftColumnImage.title}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {renderRichText(formData.formLeftField, optionsForm)}
                                </div>
                            </div>
                            <div className='col-xl-5 col-lg-5  col-sm-10 col-12 offset-sm-1'>
                                <div className='shadow p-5 bg-white rounded'>
                                    <div className='row'>
                                        <div className='col-md-12 mobile-padding-reset'>
                                            <div id='crmWebToEntityForm'>
                                                {/* <META HTTP-EQUIV='content-type' CONTENT='text/html;charset=UTF-8'> */}
                                                <form
                                                    action='https://crm.zoho.com/crm/WebToLeadForm'
                                                    name='WebToLeads2894378000030902075'
                                                    method='POST'
                                                    onSubmit={onClick}
                                                    acceptCharset='UTF-8'>
                                                    <input
                                                        type='text'
                                                        style={{ display: 'none' }}
                                                        name='xnQsjsdp'
                                                        value='2bcd19d990ceb9b3b010568f6bce478ada9ab3353e3114ed68e46a1dd30c4b9a'></input>
                                                    <input
                                                        type='hidden'
                                                        name='zc_gad'
                                                        id='zc_gad'
                                                        value=''></input>
                                                    <input
                                                        type='text'
                                                        style={{ display: 'none' }}
                                                        name='xmIwtLD'
                                                        value='c6bc8e25a8575533ee070b766cb1e1164b056c444f59c94b87430a230429f942'></input>
                                                    <input
                                                        type='text'
                                                        style={{ display: 'none' }}
                                                        name='actionType'
                                                        value='TGVhZHM='></input>
                                                    <input
                                                        type='text'
                                                        style={{ display: 'none' }}
                                                        name='returnURL'
                                                        id='returnURL'
                                                        value={formData.ctaLink}></input>
                                                    {/* <!-- Do not remove this code. --> */}
                                                    <input
                                                        type='text'
                                                        style={{ display: 'none' }}
                                                        id='ldeskuid'
                                                        name='ldeskuid'></input>
                                                    <input
                                                        type='text'
                                                        style={{ display: 'none' }}
                                                        id='LDTuvid'
                                                        name='LDTuvid'></input>
                                                    {/* <!-- Do not remove this code. --> */}
                                                    {hasName === true &&
                                                        (nameRequired === true ? (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                type='text'
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='80'
                                                                name='Last Name'
                                                                required
                                                                placeholder='Name*'
                                                            />
                                                        ) : (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                type='text'
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='80'
                                                                name='Last Name'
                                                                placeholder='Name'
                                                            />
                                                        ))}

                                                    {hasEmail === true &&
                                                        (emailRequired === true ? (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                id='email'
                                                                type='email'
                                                                required
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='100'
                                                                name='Email'
                                                                placeholder='Email*'
                                                                // onKeyUp={validateEmail}
                                                            />
                                                        ) : (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                id='email'
                                                                type='email'
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='100'
                                                                name='Email'
                                                                placeholder='Email'
                                                                // onKeyUp={validateEmail}
                                                            />
                                                        ))}
                                                    <div className='invalid-feedback ml-2'>
                                                        Please provide your professional email
                                                    </div>

                                                    {/* <!-- Input Group --> */}
                                                    {hasCountry === true &&
                                                    hasTelephone === true ? (
                                                        <div className='input-group ml-2 '>
                                                            {hasCountry === true &&
                                                                (countryRequired === true ? (
                                                                    <select
                                                                        className='my-1 bg-white countries-dropdown'
                                                                        name='LEADCF151'
                                                                        required>
                                                                        <option value=''>
                                                                            Country*
                                                                        </option>
                                                                        <option value='AFG&#x20;&#x2b;93'>
                                                                            AFG +93
                                                                        </option>
                                                                        <option value='ALB&#x20;&#x2b;355'>
                                                                            ALB +355
                                                                        </option>
                                                                        <option value='ARG&#x20;&#x2b;54'>
                                                                            ARG +54
                                                                        </option>
                                                                        <option value='AUS&#x20;&#x2b;61'>
                                                                            AUS +61
                                                                        </option>
                                                                        <option value='AUT&#x20;&#x2b;43'>
                                                                            AUT +43
                                                                        </option>
                                                                        <option value='BEL&#x20;&#x2b;32'>
                                                                            BEL +32
                                                                        </option>
                                                                        <option value='BIH&#x20;&#x2b;387'>
                                                                            BIH +387
                                                                        </option>
                                                                        <option value='BRA&#x20;&#x2b;55'>
                                                                            BRA +55
                                                                        </option>
                                                                        <option value='BG&#x20;&#x2b;359'>
                                                                            BGR +359
                                                                        </option>
                                                                        <option value='CA&#x20;&#x2b;1'>
                                                                            CAN +1
                                                                        </option>
                                                                        <option value='CH&#x20;&#x2b;41'>
                                                                            CHE +41
                                                                        </option>
                                                                        <option value='CHL&#x20;&#x2b;56'>
                                                                            CHL +56
                                                                        </option>
                                                                        <option value='CN&#x20;&#x2b;86'>
                                                                            CHN +86
                                                                        </option>
                                                                        <option value='CO&#x20;&#x2b;57'>
                                                                            COL +57
                                                                        </option>
                                                                        <option value='CR&#x20;&#x2b;506'>
                                                                            CRI +506
                                                                        </option>
                                                                        <option value='CY&#x20;&#x2b;357'>
                                                                            CYP +357
                                                                        </option>
                                                                        <option value='CZ&#x20;&#x2b;420'>
                                                                            CZE +420
                                                                        </option>
                                                                        <option value='DE&#x20;&#x2b;49'>
                                                                            DEU +49
                                                                        </option>
                                                                        <option value='DK&#x20;&#x2b;45'>
                                                                            DNK +45
                                                                        </option>
                                                                        <option value='EG&#x20;&#x2b;20'>
                                                                            EGY +20
                                                                        </option>
                                                                        <option value='ES&#x20;&#x2b;34'>
                                                                            ESP +34
                                                                        </option>
                                                                        <option value='EE&#x20;&#x2b;372'>
                                                                            EST +372
                                                                        </option>
                                                                        <option value='FI&#x20;&#x2b;358'>
                                                                            FIN +358
                                                                        </option>
                                                                        <option value='FR&#x20;&#x2b;33'>
                                                                            FRA +33
                                                                        </option>
                                                                        <option value='GR&#x20;&#x2b;30'>
                                                                            GRC +30
                                                                        </option>
                                                                        <option value='HR&#x20;&#x2b;385'>
                                                                            HRV +385
                                                                        </option>
                                                                        <option value='IND&#x20;&#x2b;91'>
                                                                            IND +91
                                                                        </option>
                                                                        <option value='IS&#x20;&#x2b;354'>
                                                                            ISL +354
                                                                        </option>
                                                                        <option value='IT&#x20;&#x2b;39'>
                                                                            ITA +39
                                                                        </option>
                                                                        <option value='JP&#x20;&#x2b;81'>
                                                                            JPN +81
                                                                        </option>
                                                                        <option value='NL&#x20;&#x2b;31'>
                                                                            NLD +31
                                                                        </option>
                                                                        <option value='NZ&#x20;&#x2b;64'>
                                                                            NZL +64
                                                                        </option>
                                                                        <option value='NG&#x20;&#x2b;234'>
                                                                            NGA +234
                                                                        </option>
                                                                        <option value='NO&#x20;&#x2b;47'>
                                                                            NOR +47
                                                                        </option>
                                                                        <option value='PT&#x20;&#x2b;351'>
                                                                            PRT +351
                                                                        </option>
                                                                        <option value='RU&#x20;&#x2b;7'>
                                                                            RUS +7
                                                                        </option>
                                                                        <option value='TR&#x20;&#x2b;90'>
                                                                            TUR +90
                                                                        </option>
                                                                        <option value='UA&#x20;&#x2b;380'>
                                                                            UKR +380
                                                                        </option>
                                                                        <option value='UK&#x20;&#x2b;44'>
                                                                            UK +44
                                                                        </option>
                                                                        <option value='US&#x20;&#x2b;1'>
                                                                            USA +1
                                                                        </option>
                                                                        <option value='other'>
                                                                            Other
                                                                        </option>
                                                                    </select>
                                                                ) : (
                                                                    <select
                                                                        className='my-1 bg-white countries-dropdown'
                                                                        name='LEADCF151'>
                                                                        <option value=''>
                                                                            Country
                                                                        </option>
                                                                        <option value='AFG&#x20;&#x2b;93'>
                                                                            AFG +93
                                                                        </option>
                                                                        <option value='ALB&#x20;&#x2b;355'>
                                                                            ALB +355
                                                                        </option>
                                                                        <option value='ARG&#x20;&#x2b;54'>
                                                                            ARG +54
                                                                        </option>
                                                                        <option value='AUS&#x20;&#x2b;61'>
                                                                            AUS +61
                                                                        </option>
                                                                        <option value='AUT&#x20;&#x2b;43'>
                                                                            AUT +43
                                                                        </option>
                                                                        <option value='BEL&#x20;&#x2b;32'>
                                                                            BEL +32
                                                                        </option>
                                                                        <option value='BIH&#x20;&#x2b;387'>
                                                                            BIH +387
                                                                        </option>
                                                                        <option value='BRA&#x20;&#x2b;55'>
                                                                            BRA +55
                                                                        </option>
                                                                        <option value='BG&#x20;&#x2b;359'>
                                                                            BGR +359
                                                                        </option>
                                                                        <option value='CA&#x20;&#x2b;1'>
                                                                            CAN +1
                                                                        </option>
                                                                        <option value='CH&#x20;&#x2b;41'>
                                                                            CHE +41
                                                                        </option>
                                                                        <option value='CHL&#x20;&#x2b;56'>
                                                                            CHL +56
                                                                        </option>
                                                                        <option value='CN&#x20;&#x2b;86'>
                                                                            CHN +86
                                                                        </option>
                                                                        <option value='CO&#x20;&#x2b;57'>
                                                                            COL +57
                                                                        </option>
                                                                        <option value='CR&#x20;&#x2b;506'>
                                                                            CRI +506
                                                                        </option>
                                                                        <option value='CY&#x20;&#x2b;357'>
                                                                            CYP +357
                                                                        </option>
                                                                        <option value='CZ&#x20;&#x2b;420'>
                                                                            CZE +420
                                                                        </option>
                                                                        <option value='DE&#x20;&#x2b;49'>
                                                                            DEU +49
                                                                        </option>
                                                                        <option value='DK&#x20;&#x2b;45'>
                                                                            DNK +45
                                                                        </option>
                                                                        <option value='EG&#x20;&#x2b;20'>
                                                                            EGY +20
                                                                        </option>
                                                                        <option value='ES&#x20;&#x2b;34'>
                                                                            ESP +34
                                                                        </option>
                                                                        <option value='EE&#x20;&#x2b;372'>
                                                                            EST +372
                                                                        </option>
                                                                        <option value='FI&#x20;&#x2b;358'>
                                                                            FIN +358
                                                                        </option>
                                                                        <option value='FR&#x20;&#x2b;33'>
                                                                            FRA +33
                                                                        </option>
                                                                        <option value='GR&#x20;&#x2b;30'>
                                                                            GRC +30
                                                                        </option>
                                                                        <option value='HR&#x20;&#x2b;385'>
                                                                            HRV +385
                                                                        </option>
                                                                        <option value='IND&#x20;&#x2b;91'>
                                                                            IND +91
                                                                        </option>
                                                                        <option value='IS&#x20;&#x2b;354'>
                                                                            ISL +354
                                                                        </option>
                                                                        <option value='IT&#x20;&#x2b;39'>
                                                                            ITA +39
                                                                        </option>
                                                                        <option value='JP&#x20;&#x2b;81'>
                                                                            JPN +81
                                                                        </option>
                                                                        <option value='NL&#x20;&#x2b;31'>
                                                                            NLD +31
                                                                        </option>
                                                                        <option value='NZ&#x20;&#x2b;64'>
                                                                            NZL +64
                                                                        </option>
                                                                        <option value='NG&#x20;&#x2b;234'>
                                                                            NGA +234
                                                                        </option>
                                                                        <option value='NO&#x20;&#x2b;47'>
                                                                            NOR +47
                                                                        </option>
                                                                        <option value='PT&#x20;&#x2b;351'>
                                                                            PRT +351
                                                                        </option>
                                                                        <option value='RU&#x20;&#x2b;7'>
                                                                            RUS +7
                                                                        </option>
                                                                        <option value='TR&#x20;&#x2b;90'>
                                                                            TUR +90
                                                                        </option>
                                                                        <option value='UA&#x20;&#x2b;380'>
                                                                            UKR +380
                                                                        </option>
                                                                        <option value='UK&#x20;&#x2b;44'>
                                                                            UK +44
                                                                        </option>
                                                                        <option value='US&#x20;&#x2b;1'>
                                                                            USA +1
                                                                        </option>
                                                                        <option value='other'>
                                                                            Other
                                                                        </option>
                                                                    </select>
                                                                ))}

                                                            {hasTelephone === true &&
                                                                (telephoneRequired === true ? (
                                                                    <input
                                                                        className='my-1 ml-0 form-control input-group-append  p-4 border-left-0'
                                                                        type='text'
                                                                        maxLength='30'
                                                                        required
                                                                        name='Phone'
                                                                        placeholder='Phone Number*'
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        className='my-1 ml-0 form-control input-group-append  p-4 border-left-0'
                                                                        type='text'
                                                                        maxLength='30'
                                                                        name='Phone'
                                                                        placeholder='Phone Number'
                                                                    />
                                                                ))}
                                                        </div>
                                                    ) : (
                                                        <Fragment>
                                                            {hasCountry === true &&
                                                                (countryRequired === true ? (
                                                                    <select
                                                                        className='my-1 bg-white countries-dropdown'
                                                                        name='LEADCF151'
                                                                        required>
                                                                        <option value=''>
                                                                            Country*
                                                                        </option>
                                                                        <option value='AFG&#x20;&#x2b;93'>
                                                                            AFG +93
                                                                        </option>
                                                                        <option value='ALB&#x20;&#x2b;355'>
                                                                            ALB +355
                                                                        </option>
                                                                        <option value='ARG&#x20;&#x2b;54'>
                                                                            ARG +54
                                                                        </option>
                                                                        <option value='AUS&#x20;&#x2b;61'>
                                                                            AUS +61
                                                                        </option>
                                                                        <option value='AUT&#x20;&#x2b;43'>
                                                                            AUT +43
                                                                        </option>
                                                                        <option value='BEL&#x20;&#x2b;32'>
                                                                            BEL +32
                                                                        </option>
                                                                        <option value='BIH&#x20;&#x2b;387'>
                                                                            BIH +387
                                                                        </option>
                                                                        <option value='BRA&#x20;&#x2b;55'>
                                                                            BRA +55
                                                                        </option>
                                                                        <option value='BG&#x20;&#x2b;359'>
                                                                            BGR +359
                                                                        </option>
                                                                        <option value='CA&#x20;&#x2b;1'>
                                                                            CAN +1
                                                                        </option>
                                                                        <option value='CH&#x20;&#x2b;41'>
                                                                            CHE +41
                                                                        </option>
                                                                        <option value='CHL&#x20;&#x2b;56'>
                                                                            CHL +56
                                                                        </option>
                                                                        <option value='CN&#x20;&#x2b;86'>
                                                                            CHN +86
                                                                        </option>
                                                                        <option value='CO&#x20;&#x2b;57'>
                                                                            COL +57
                                                                        </option>
                                                                        <option value='CR&#x20;&#x2b;506'>
                                                                            CRI +506
                                                                        </option>
                                                                        <option value='CY&#x20;&#x2b;357'>
                                                                            CYP +357
                                                                        </option>
                                                                        <option value='CZ&#x20;&#x2b;420'>
                                                                            CZE +420
                                                                        </option>
                                                                        <option value='DE&#x20;&#x2b;49'>
                                                                            DEU +49
                                                                        </option>
                                                                        <option value='DK&#x20;&#x2b;45'>
                                                                            DNK +45
                                                                        </option>
                                                                        <option value='EG&#x20;&#x2b;20'>
                                                                            EGY +20
                                                                        </option>
                                                                        <option value='ES&#x20;&#x2b;34'>
                                                                            ESP +34
                                                                        </option>
                                                                        <option value='EE&#x20;&#x2b;372'>
                                                                            EST +372
                                                                        </option>
                                                                        <option value='FI&#x20;&#x2b;358'>
                                                                            FIN +358
                                                                        </option>
                                                                        <option value='FR&#x20;&#x2b;33'>
                                                                            FRA +33
                                                                        </option>
                                                                        <option value='GR&#x20;&#x2b;30'>
                                                                            GRC +30
                                                                        </option>
                                                                        <option value='HR&#x20;&#x2b;385'>
                                                                            HRV +385
                                                                        </option>
                                                                        <option value='IND&#x20;&#x2b;91'>
                                                                            IND +91
                                                                        </option>
                                                                        <option value='IS&#x20;&#x2b;354'>
                                                                            ISL +354
                                                                        </option>
                                                                        <option value='IT&#x20;&#x2b;39'>
                                                                            ITA +39
                                                                        </option>
                                                                        <option value='JP&#x20;&#x2b;81'>
                                                                            JPN +81
                                                                        </option>
                                                                        <option value='NL&#x20;&#x2b;31'>
                                                                            NLD +31
                                                                        </option>
                                                                        <option value='NZ&#x20;&#x2b;64'>
                                                                            NZL +64
                                                                        </option>
                                                                        <option value='NG&#x20;&#x2b;234'>
                                                                            NGA +234
                                                                        </option>
                                                                        <option value='NO&#x20;&#x2b;47'>
                                                                            NOR +47
                                                                        </option>
                                                                        <option value='PT&#x20;&#x2b;351'>
                                                                            PRT +351
                                                                        </option>
                                                                        <option value='RU&#x20;&#x2b;7'>
                                                                            RUS +7
                                                                        </option>
                                                                        <option value='TR&#x20;&#x2b;90'>
                                                                            TUR +90
                                                                        </option>
                                                                        <option value='UA&#x20;&#x2b;380'>
                                                                            UKR +380
                                                                        </option>
                                                                        <option value='UK&#x20;&#x2b;44'>
                                                                            UK +44
                                                                        </option>
                                                                        <option value='US&#x20;&#x2b;1'>
                                                                            USA +1
                                                                        </option>
                                                                        <option value='other'>
                                                                            Other
                                                                        </option>
                                                                    </select>
                                                                ) : (
                                                                    <select
                                                                        className='my-1 bg-white countries-dropdown'
                                                                        name='LEADCF151'>
                                                                        <option value=''>
                                                                            Country
                                                                        </option>
                                                                        <option value='AFG&#x20;&#x2b;93'>
                                                                            AFG +93
                                                                        </option>
                                                                        <option value='ALB&#x20;&#x2b;355'>
                                                                            ALB +355
                                                                        </option>
                                                                        <option value='ARG&#x20;&#x2b;54'>
                                                                            ARG +54
                                                                        </option>
                                                                        <option value='AUS&#x20;&#x2b;61'>
                                                                            AUS +61
                                                                        </option>
                                                                        <option value='AUT&#x20;&#x2b;43'>
                                                                            AUT +43
                                                                        </option>
                                                                        <option value='BEL&#x20;&#x2b;32'>
                                                                            BEL +32
                                                                        </option>
                                                                        <option value='BIH&#x20;&#x2b;387'>
                                                                            BIH +387
                                                                        </option>
                                                                        <option value='BRA&#x20;&#x2b;55'>
                                                                            BRA +55
                                                                        </option>
                                                                        <option value='BG&#x20;&#x2b;359'>
                                                                            BGR +359
                                                                        </option>
                                                                        <option value='CA&#x20;&#x2b;1'>
                                                                            CAN +1
                                                                        </option>
                                                                        <option value='CH&#x20;&#x2b;41'>
                                                                            CHE +41
                                                                        </option>
                                                                        <option value='CHL&#x20;&#x2b;56'>
                                                                            CHL +56
                                                                        </option>
                                                                        <option value='CN&#x20;&#x2b;86'>
                                                                            CHN +86
                                                                        </option>
                                                                        <option value='CO&#x20;&#x2b;57'>
                                                                            COL +57
                                                                        </option>
                                                                        <option value='CR&#x20;&#x2b;506'>
                                                                            CRI +506
                                                                        </option>
                                                                        <option value='CY&#x20;&#x2b;357'>
                                                                            CYP +357
                                                                        </option>
                                                                        <option value='CZ&#x20;&#x2b;420'>
                                                                            CZE +420
                                                                        </option>
                                                                        <option value='DE&#x20;&#x2b;49'>
                                                                            DEU +49
                                                                        </option>
                                                                        <option value='DK&#x20;&#x2b;45'>
                                                                            DNK +45
                                                                        </option>
                                                                        <option value='EG&#x20;&#x2b;20'>
                                                                            EGY +20
                                                                        </option>
                                                                        <option value='ES&#x20;&#x2b;34'>
                                                                            ESP +34
                                                                        </option>
                                                                        <option value='EE&#x20;&#x2b;372'>
                                                                            EST +372
                                                                        </option>
                                                                        <option value='FI&#x20;&#x2b;358'>
                                                                            FIN +358
                                                                        </option>
                                                                        <option value='FR&#x20;&#x2b;33'>
                                                                            FRA +33
                                                                        </option>
                                                                        <option value='GR&#x20;&#x2b;30'>
                                                                            GRC +30
                                                                        </option>
                                                                        <option value='HR&#x20;&#x2b;385'>
                                                                            HRV +385
                                                                        </option>
                                                                        <option value='IND&#x20;&#x2b;91'>
                                                                            IND +91
                                                                        </option>
                                                                        <option value='IS&#x20;&#x2b;354'>
                                                                            ISL +354
                                                                        </option>
                                                                        <option value='IT&#x20;&#x2b;39'>
                                                                            ITA +39
                                                                        </option>
                                                                        <option value='JP&#x20;&#x2b;81'>
                                                                            JPN +81
                                                                        </option>
                                                                        <option value='NL&#x20;&#x2b;31'>
                                                                            NLD +31
                                                                        </option>
                                                                        <option value='NZ&#x20;&#x2b;64'>
                                                                            NZL +64
                                                                        </option>
                                                                        <option value='NG&#x20;&#x2b;234'>
                                                                            NGA +234
                                                                        </option>
                                                                        <option value='NO&#x20;&#x2b;47'>
                                                                            NOR +47
                                                                        </option>
                                                                        <option value='PT&#x20;&#x2b;351'>
                                                                            PRT +351
                                                                        </option>
                                                                        <option value='RU&#x20;&#x2b;7'>
                                                                            RUS +7
                                                                        </option>
                                                                        <option value='TR&#x20;&#x2b;90'>
                                                                            TUR +90
                                                                        </option>
                                                                        <option value='UA&#x20;&#x2b;380'>
                                                                            UKR +380
                                                                        </option>
                                                                        <option value='UK&#x20;&#x2b;44'>
                                                                            UK +44
                                                                        </option>
                                                                        <option value='US&#x20;&#x2b;1'>
                                                                            USA +1
                                                                        </option>
                                                                        <option value='other'>
                                                                            Other
                                                                        </option>
                                                                    </select>
                                                                ))}

                                                            {hasTelephone === true &&
                                                                (telephoneRequired === true ? (
                                                                    <input
                                                                        className='my-1 ml-0 form-control input-group-append  p-4 border-left-0'
                                                                        type='text'
                                                                        maxLength='30'
                                                                        required
                                                                        name='Phone'
                                                                        placeholder='Phone Number*'
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        className='my-1 ml-0 form-control input-group-append  p-4 border-left-0'
                                                                        type='text'
                                                                        maxLength='30'
                                                                        name='Phone'
                                                                        placeholder='Phone Number'
                                                                    />
                                                                ))}
                                                        </Fragment>
                                                    )}

                                                    {/* <!-- Phone & country ^ --> */}
                                                    {hasCompany === true &&
                                                        (companyRequired === true ? (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                type='text'
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='100'
                                                                required
                                                                name='Company'
                                                                placeholder='Company*'
                                                            />
                                                        ) : (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                type='text'
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='100'
                                                                name='Company'
                                                                placeholder='Company'
                                                            />
                                                        ))}

                                                    {hasJobTitle === true &&
                                                        (jobTitleRequired === true ? (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                type='text'
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='30'
                                                                name='Designation'
                                                                placeholder='Job Title*'
                                                                required
                                                            />
                                                        ) : (
                                                            <input
                                                                className='m-2 form-control p-4'
                                                                type='text'
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                                maxLength='30'
                                                                name='Designation'
                                                                placeholder='Job Title'
                                                            />
                                                        ))}

                                                    {hasTextMessage === true &&
                                                        (textMessageRequired === true ? (
                                                            <textarea
                                                                id='textarea'
                                                                className='m-2 form-control p-4'
                                                                name='LEADCF24'
                                                                maxLength='2000'
                                                                style={{ width: '100%' }}
                                                                placeholder='Tell us about your use case *'
                                                                required></textarea>
                                                        ) : (
                                                            <textarea
                                                                id='textarea'
                                                                className='m-2 form-control p-4'
                                                                name='LEADCF24'
                                                                maxLength='2000'
                                                                style={{ width: '100%' }}
                                                                placeholder='Tell us about your use case '></textarea>
                                                        ))}

                                                    <span style={{ display: 'none' }}>
                                                        {/* <!-- End Trial Fields --> */}
                                                        <input
                                                            type='text'
                                                            maxLength='255'
                                                            id='lead-source'
                                                            name='Lead Source'
                                                            value={formData.formSourceForCrm}
                                                        />
                                                    </span>

                                                    {/* <input className="m-2" type='checkbox' name='LEADCF102' /> Add me to Newsletter! */}
                                                    <div className='m-2'>
                                                        <input
                                                            type='checkbox'
                                                            name='LEADCF104'
                                                            required
                                                        />{' '}
                                                        <span className='pl-1'>
                                                            By submitting the form, you agree to our{' '}
                                                            <a
                                                                className='link-text'
                                                                href='/legals/terms-and-conditions/'>
                                                                Terms of Use{' '}
                                                            </a>
                                                            and{' '}
                                                            <a
                                                                className='link-text'
                                                                href='/legals/policy/'>
                                                                Privacy Policy
                                                            </a>
                                                        </span>
                                                    </div>

                                                    <ReCAPTCHA
                                                        className='ml-2'
                                                        sitekey='6LfNhSEUAAAAAL9OJM7RO856GguxnnKDM-uveavH'
                                                        onChange={onChange}
                                                    />

                                                    <div>
                                                        <input
                                                            className='ml-2 mt-3 btn btn-lg btn-prim text-uppercase w-100 mb-5'
                                                            id={
                                                                formData.buttonId
                                                                    ? formData.buttonId
                                                                    : 'formsubmit'
                                                            }
                                                            type='submit'
                                                            value={formData.ctaText}
                                                            disabled></input>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* BG COVER */}
                        </div>
                    </div>
                </section>
                <Spacing />
            </Fragment>
        )
    }

    return form
}

export default formSection
